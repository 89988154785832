@font-face {
    font-family: 'Samarkan';
    src: url('SamarkanNormal.woff2') format('woff2'),
        url('SamarkanNormal.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Samarkan';
    src: url('SamarkanOblique.woff2') format('woff2'),
        url('SamarkanOblique.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

