@font-face {
    font-family: 'Objectivity';
    src: url('Objectivity-Regular.eot');
    src: url('Objectivity-Regular.eot?#iefix') format('embedded-opentype'),
        url('Objectivity-Regular.woff2') format('woff2'),
        url('Objectivity-Regular.woff') format('woff'),
        url('Objectivity-Regular.ttf') format('truetype'),
        url('Objectivity-Regular.svg#Objectivity-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Objectivity';
    src: url('Objectivity-Medium.eot');
    src: url('Objectivity-Medium.eot?#iefix') format('embedded-opentype'),
        url('Objectivity-Medium.woff2') format('woff2'),
        url('Objectivity-Medium.woff') format('woff'),
        url('Objectivity-Medium.ttf') format('truetype'),
        url('Objectivity-Medium.svg#Objectivity-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Objectivity';
    src: url('Objectivity-Bold.eot');
    src: url('Objectivity-Bold.eot?#iefix') format('embedded-opentype'),
        url('Objectivity-Bold.woff2') format('woff2'),
        url('Objectivity-Bold.woff') format('woff'),
        url('Objectivity-Bold.ttf') format('truetype'),
        url('Objectivity-Bold.svg#Objectivity-Bold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
