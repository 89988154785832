* {
  box-sizing: border-box;
}

body {
  background: #fef9f3;
  font-family: "Objectivity";
  font-style: normal;
}

@import "./base/buttons";

@import "./components/header";

@import "./pages/home";

@import url("../objectivity/objectivity-font.css");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,600;0,800;1,400&display=swap");

@import url("../samarkan/stylesheet.css");

.banner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 220px 0px 0px 0px;
}

.app-component-header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  // background-color: #fff;
  background-color: #fef9f3;
}

#AppBanner {
  position: relative;
  // background-color: #f1f1f1;
  height: 100%;
  width: 100%;
}

#AppBanner .om-left {
  position: absolute;
  left: 0;
  top: 20%;
}

#AppBanner .cloud-left {
  position: absolute;
  left: 0;
  top: 50%;
}

#AppBanner .cloud1-left {
  position: absolute;
  left: 16%;
  top: 40%;
}

#AppBanner .om-right {
  position: absolute;
  right: 0;
  top: 18%;
}

#AppBanner .cloud-right {
  position: absolute;
  right: 0;
  top: 40%;
}

#AppBanner .cloud1-right {
  position: absolute;
  right: 10%;
  top: 50%;
}

.readmore-desc {
  max-height: 100px;
  overflow: hidden;
}
.description-scroll {
  overflow: hidden;
  position: relative;
  overflow-y: scroll;
}
.description-scroll::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

// .description-scroll::-webkit-scrollbar-thumb {
//   background-color: #888; /* Color of the scrollbar thumb */
//   border-radius: 6px; /* Rounded corners of the thumb */
// }

// .description-scroll::-webkit-scrollbar-track {
//   background-color:red; /* Color of the scrollbar track */
// }

.banner-content h1 {
  color: #242424;
  // font-family: "Samarkan";
  font-family: "Arial", sans-serif;
  text-align: center;
  font-size: 60px;
  font-style: normal;
  font-weight: 800;
  line-height: 90px;
  // width: 900px;
  margin: 0 0 20px 0;
}

.banner-content h1 span {
  color: #ff5b00;
  display: block;
}

.banner-content p {
  color: #242424;
  font-family: "Objectivity";
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  width: 750px;
  margin: 0;
}

.banner-image {
  padding: 30px 0px 0px 0px;
}

.banner-image img {
  width: 100%;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 10px !important;
  font-weight: bolder !important;
  border: 2px solid #ff5b00 !important;
  padding: 6px 8px !important;
  color: #ff5b00 !important;
  border-radius: 60% !important;
}
.banner-image .banner-lp {
  display: block;
}

.banner-image .banner-mb {
  display: none;
}
.download-inv {
  border-radius: 40.87px;
  background: #ff5b00;
  color: #fff;
  font-family: "Objectivity";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: unset;
  padding: 15px 30px;
  border: 1px solid #ff5b00;
  cursor: pointer;
}
.right-down {
  display: flex;
  align-items: center;
}
.app-btn {
  // border-radius: 40.87px !important;
  border-radius: 12px !important;
  background: #ff5b00 !important;
  padding: 10px 30px !important;
  margin-top: 30px !important;
  text-transform: unset !important;
}

.app-btn-text {
  font-size: 15px;
  line-height: 25px;
  font-family: "Objectivity";
  font-weight: 700;
}

.app-btn-img {
  margin-left: 10px;
  width: 18px;
  height: 18px;
}

.discover-more {
  margin-top: 90px;
}

.discover-more-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.discover-more-buttons h2 {
  color: #2c2c2c;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}
.form-group input[readonly] {
  background-color: #f2f2f2; /* Change this to the desired color for read-only */
  color: #808080; /* Change this to the desired text color for read-only */
  border: 1px solid #ddd; /* Change this to the desired border color for read-only */
}

.form-group.editable input {
  background-color: #fff; /* Change this to the desired color for editable */
  color: #000; /* Change this to the desired text color for editable */
  border: 1px solid #ccc; /* Change this to the desired border color for editable */
}

/* Add this to your CSS or styles */
.disabledButton {
  /* Add your styling for the disabled button */
  background-color: lightgray; /* Example background color */
  color: gray; /* Example text color */
  cursor: not-allowed;
  border-radius: 40.87px !important;
  font-family: Objectivity !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  line-height: 25px !important;
  padding: 10px 40px !important;
  text-transform: inherit !important;
}

.disabledButton1 {
  /* Add your styling for the disabled button */
  background-color: #c0c0c0 !important; /* Example background color */
  color: white !important; /* Example text color */
  cursor: not-allowed;
  border-radius: 15px !important;
  font-family: Objectivity !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  line-height: 25px !important;
  padding: 6px 40px !important;
  text-transform: inherit !important;
}

.discover-more-buttons Button {
  color: #ff5b00;
  font-family: "Objectivity";
  leading-trim: both;
  text-edge: cap;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  display: flex;
  align-items: center;
  text-transform: unset;
  text-decoration: none;
}

.discover-more-buttons Button:hover {
  box-shadow: unset !important;
  background-color: unset !important;
}

a:-webkit-any-link {
  text-decoration: none;
  display: block;
}

.discover-more-boxs {
  margin: 10px 0px;
  position: relative;
  z-index: 1;
}

.discover-more-content .swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 20px !important;
}

.discover-more-content .swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  display: none !important;
}

.discover-more-content .swiper-horizontal,
.categories-tabs-content .swiper-horizontal {
  padding-bottom: 40px;
}

.discover-more-content .swiper-pagination-bullet-active {
  background-color: #ff5b00 !important;
}

// .nava-chandi-bg {
//   width: 100%;
//   // background-image: url("../images/discover-more/bg.svg");
//   background-repeat: no-repeat;
//   background-size: 100%;
//   height: 100%;
//   padding: 20px;
//   border-radius: 10px;
//   border: 1px solid #b8adab;
//   position: absolute;
// }

.nava-chandi-bg {
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ff5b00;
  position: relative;
  overflow: hidden;
  z-index: 9999;
}

.nava-chandi-bg::after {
  content: "";
  position: absolute;
  right: -80px;
  bottom: -140px;
  width: 80%;
  height: 80%;
  background-image: url("../images/discover-more/mandala.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right bottom;
  z-index: 1;
}

.nava-chandi-bg h4 {
  color: #ff5b00;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  margin: 0;
  // min-height: 72px;
  min-height: 90px;
}

.nava-chandi-bg p {
  color: #505050;
  font-family: "Objectivity";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin: 0;
}

.nava-chandi-bg p span {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin: 0;
  color: #ff5b00;
  cursor: pointer;
}

.durgamata {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -110px;
}
.sevacard-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.durgamata {
  position: relative;

  height: 300px;
}
._9hyx {
  border: none !important;
  color: black !important;
  font-size: 25px !important;
  cursor: pointer !important;
}
.durgamata img {
  width: 100%;
  height: 100%;
}
._3gpv {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.custom-champaign-parent-box {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  // height: 330px;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  margin-top: 135px;
  // max-width: 1200px;
}

._3gpv .campaign-social-icons {
  position: absolute;
  bottom: 20px;
  left: 15px;
  border-radius: 22px;
  background-color: white;
}

._3gpv .campaign-social-icons > .footer-social-icons {
  display: flex;
  margin-top: unset;
}

._3gpv .campaign-social-icons > .footer-social-icons > div {
  width: 28px;
  height: 28px;
  margin: 8px 10px;
}

._3gpv .campaign-social-icons > .footer-social-icons > div > a > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

._3gpv > img {
  width: 100%;

  background-repeat: no-repeat;
  background-size: 100%;
  height: 100%;
  position: relative;
  // margin-top: 130px;
  // border-radius: 10px;
}

.durgamata .strip {
  // position: absolute;
  // bottom: -5px;
  // left: 51%;
  // margin-left: 50px;
  // transform: translate(-50%, -50%);
}

.unselected-address {
  border: 2px solid red;
}
.durgamata .date-time {
  // position: absolute;
  // bottom: -30%;
  // left: 50%;
  // transform: translate(-50%, -50%);
}

.durgamata .date-time p {
  color: red;

  font-size: 14px;

  font-style: normal;
  font-weight: 700;
  line-height: normal;
  // margin: 0;
  text-align: center;
  margin-top: -30px;
}
.sevacard-img {
  height: 250px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.sevacard-img img {
  width: 100%;
  height: 100%;
  // margin-top: -53.5px;
  border-radius: 8px;
}
.seva-title {
  border-top: 1px solid gray;
  // border-radius: 19px;
  position: absolute;
  background-color: #fef9f3;
  top: 200px;
}
.traditional-strip {
  // width:100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  position: absolute;
  margin-top: -20px;
  z-index: 1;
  top: 160px;
  left: 10px;
  // left: 0;
}
.traditional-strip p {
  padding: 10px 20px;
  // background: linear-gradient(to right, #ff5e62, #ff9966);
  background: #ff5b00;
  border: 1px solid #fafafa;
  box-shadow: 0 0 8px #bfbfbf;
  margin: 0;
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  border-radius: 25px;
}
// .traditional-strip::after{
//   content: "";
//   width: 100%;
//   height: 50px;
//   background-image: url('../images/strip-card.svg');
//   background-size: 100%;
//   position: absolute;
//   top:0;
//   left:0;
// }

.modal-button {
  position: relative;
  top: 0px;
  right: -45px;
  background-color: transparent black;
  color: gray;
  width: 35px;
  height: 35px;
  font-size: 20px;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  z-index: 1400;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
}
.modal-button:hover {
  background-color: #ff7f00;
}

.welness_head_div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 120px;
  left: 25%;
}

.welness_head {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: red;
}
.p-0 {
  padding: 0 !important;
}
//  vishal
.add-new-strip-box {
  position: relative;
}
.add-new-strip-box.nava-chandi-bg h4 {
  padding: 20px 20px 10px 20px;
}
.add-new-strip-box.nava-chandi-bg .book-seva {
  padding: 15px 0 25px 0;
}
.multiselect-option.choose-seva-content {
  width: 100%;
  margin-bottom: 10px;
}
.multiselect-option.choose-seva-content .MuiFormControl-root {
  width: 100%;
}
.multiselect-option.choose-seva-content .MuiOutlinedInput-notchedOutline {
  border-radius: 8px !important;
}
.book-seva {
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9999;
}

.book-seva .book-seva-btn {
  font-size: 15px;
  line-height: 25px;
  font-family: "Objectivity";
  color: #fff;
  font-weight: 700;
  // border-radius: 40.87px;
  border-radius: 12px;
  background: #ff5b00;
  padding: 10px 40px;
  text-transform: unset !important;
}

.book-seva .book-seva-btn:hover {
  font-size: 15px;
  line-height: 25px;
  font-family: "Objectivity";
  color: #fff;
  font-weight: 700;
  border-radius: 12px;
  background: #ff5b00;
  padding: 10px 40px;
  text-transform: unset !important;
}

.our-special-offers {
  margin: 40px 0px 80px 0px;
}

.our-special-offers-box {
  // border-radius: 16px;
  // background: #FFF;
  // box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  background-image: url("../images//discover-more//special-offers-bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px 40px;
  border-radius: 15px;
}

.our-special-offers-content h2 {
  font-size: 50px;
  font-style: normal;
  font-weight: 800;
  line-height: 60px;
  margin: 0px 0px 10px 0px;
}

.our-special-offers-content h2 span {
  color: #ff5b00;
}

.our-special-offers-content p {
  color: #505050;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
}

.our-special-offers-image img {
  width: 100%;
  height: 350px;
  object-fit: contain;
}

.featured-sevas {
  // background: linear-gradient(
  //   180deg,
  //   rgba(255, 255, 255, 0) -1.03%,
  //   #fff 41.81%
  // );
  background: linear-gradient(180deg, #fef0e7 0%, #feeadd 100%);
  position: relative;
  margin-top: 40px;
  padding: 40px 0px;
}

.featured-sevas::after {
  content: "";
  position: absolute;
  background-image: url("../images/features/bg.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 500px;
  height: 500px;
  top: -10%;
  left: 30%;
  background-size: contain;
  z-index: -1111;
}

//vishal
.featured-seva-box {
  padding: 30px 0;
}

.featured-seva-box h4 {
  color: #2c2c2c;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.fearuted-box-content {
  margin-top: 60px;
}

.box-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 30px;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  width: 290px;
}

.box-content h4 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 10px 0 10px 0;
  color: #ff5b00;
}

.box-content p {
  color: #505050;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.devasmriti-digital-main {
  margin-top: 120px;
}

.devasmriti-digital-main {
  position: relative;
}

.devasmriti-digital-main .nama-bg {
  position: absolute;
  background-image: url("../images/devasmriti-digitally/nama.svg");
  width: 300px;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  bottom: 12%;
  right: 0;
}

.devasmriti-digital {
  margin-top: 120px;
}

.devasmriti-digital-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.devasmriti-digital-content h2 {
  color: #13151e;
  text-align: center;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  margin: 0;
  width: 750px;
}

.devasmriti-digital-content h2 span {
  color: #ff5b00;
}

// vishal

.devasmriti-image {
  width: 55%;
  max-width: 270px;
  min-width: 150px;
  height: auto;
  aspect-ratio: 1 / 1;
  margin: 0 auto;
}

.devasmriti-image img {
  width: 100%;
  height: 100%;
}

.devasmriti-digital-seva-content {
  // margin-left: 120px;
  position: relative;
  text-align: center;
}

// .devasmriti-digital-seva-content::before {
//   content: "";
//   position: absolute;
//   border-left: 1px dashed #ff5b00;
//   height: 400px;
//   left: -70px;
//   top: -70px;
// }

// .devasmriti-digital-seva-content::after {
//   content: "";
//   position: absolute;
//   width: 15px;
//   height: 15px;
//   border-radius: 50%;
//   background-color: #ff5b00;
//   top: 70px;
//   left: -77px;
// }

.devasmriti-digital-seva-content-2 {
  position: relative;
}

// .devasmriti-digital-seva-content-2::before {
//   content: "";
//   position: absolute;
//   border-left: 1px dashed rgba(68, 91, 0, 0.1);
//   height: 442px;
//   left: -70px;
//   top: -110px;
// }

.devasmriti-digital-seva-content-3 {
  position: relative;
  // text-align: center;
}

// .devasmriti-digital-seva-content-3::before {
//   content: "";
//   position: absolute;
//   border-left: unset;
//   height: 0;
//   left: 0px;
//   top: 0px;
// }

// .devasmriti-digital-seva-content-2::after,
// .devasmriti-digital-seva-content-3::after {
//   content: "";
//   position: absolute;
//   width: 15px;
//   height: 15px;
//   border-radius: 50%;
//   background-color: rgba(68, 91, 0, 0.1);
//   top: 70px;
//   left: -77px;
// }

.devasmriti-digital-seva-content h2 {
  font-family: "Poppins";
  color: #ff5b00;
  font-size: 70px;
  font-style: normal;
  font-weight: 800;
  line-height: 37px;
  // margin: 0px 0px 10px 0px;
  margin: 0px 0px 22px 0px;
}

.devasmriti-digital-main
  .devasmriti-digital:nth-of-type(2)
  .devasmriti-digital-seva-content
  h2,
.devasmriti-digital-main
  .devasmriti-digital:nth-of-type(4)
  .devasmriti-digital-seva-content
  h2,
.devasmriti-digital-main
  .devasmriti-digital:nth-of-type(2)
  .devasmriti-digital-seva-content
  h5,
.devasmriti-digital-main
  .devasmriti-digital:nth-of-type(4)
  .devasmriti-digital-seva-content
  h5 {
  text-align: left;
}

.devasmriti-digital-main
  .devasmriti-digital:nth-of-type(3)
  .devasmriti-digital-seva-content
  h2,
.devasmriti-digital-main
  .devasmriti-digital:nth-of-type(5)
  .devasmriti-digital-seva-content
  h2,
.devasmriti-digital-main
  .devasmriti-digital:nth-of-type(3)
  .devasmriti-digital-seva-content
  h5,
.devasmriti-digital-main
  .devasmriti-digital:nth-of-type(5)
  .devasmriti-digital-seva-content
  h5 {
  text-align: right;
}

// .devasmriti-digital-seva-content-2 h2,
// .devasmriti-digital-seva-content-3 h2 {
//   color: rgba(68, 91, 0, 0.1);
// }

.devasmriti-digital-seva-content h5 {
  color: #13151e;
  // font-size: 18px;
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
  margin: 0px 0px 10px 0px;
}

.devasmriti-digital-seva-content h5 span {
  font-size: 25px;
  font-weight: 600;
}

.devasmriti-digital-seva-content p {
  color: #505050;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin: 0;
}

.happy-customers {
  margin-top: 150px;
  margin-bottom: 50px;
}

.happy-customers-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.happy-customers-content h3 {
  color: #ff5b00;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  margin: 0px 0px 10px 0px;
  position: relative;
}

.happy-customers-content h2 {
  color: #24292e;
  text-align: center;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
  margin: 0px 0px 10px 0px;
  position: relative;
}

//vishal

.happy-customers-content h3::after {
  content: "";
  position: absolute;
  top: -25px;
  right: -50px;
  height: 40px;
  width: 40px;
  background-image: url("../images/sparkle.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.happy-customers-content p {
  color: rgba(19, 21, 30, 0.6);
  text-align: center;
  // font-size: 20px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin: 0;
}

.happy-customers-sliders {
  margin: 60px 0px 0px 0px;
}

.happy-customers-cards {
  border-radius: 15px;
  background: #fff;
  padding: 20px;
}

.happy-customers-card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.happy-customers-card-content p {
  color: rgba(19, 21, 30, 0.6);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin: 0;
}

.happy-customer-mini-content {
  margin-top: 20px !important;
}

.card-image {
  margin-top: 20px;
}

.card-image img {
  width: 50px;
  height: 50px;
}

.card-image {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // width: 115px;
}

.card-image-content {
  // margin-left: 25px;
}

.card-image-content h5 {
  color: #1e1e1e;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  margin: 0px 0px 0px 0px;
}

.card-image-content p {
  color: #585858;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin: 0px 0px 0px 0px;
}

//vishal
.our-brands {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: white;
  border-bottom: 1px solid #e8e8e8;
}

// .our-brands-content{
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// }

// .our-brands-content h2 {
//   color: #13151e;
//   text-align: center;
//   font-size: 35px;
//   font-style: normal;
//   font-weight: 700;
//   line-height: 45px;
//   margin: 0;
// }

.our-brands-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.our-brands-content h2 {
  color: #13151e;
  text-align: center;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
  margin: 0;
  position: relative;
  padding: 0 20px;
}

.our-brands-content h2::before,
.our-brands-content h2::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 90%;
  height: 2px;
  background-color: #ff5b00;
  transform: translateY(-50%);
}

.our-brands-content h2::before {
  left: 0;
  transform: translate(-100%, -50%);
}

.our-brands-content h2::after {
  right: 0;
  transform: translate(100%, -50%);
}

.multiple-states {
  border-top: 1px solid #e8e8e8;
  padding: 60px 0;
}

.multiple-states h2 {
  color: #13151e;
  text-align: center;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
  margin: 0;
  width: 50%;
  padding-top: 40px;
  padding-bottom: 60px;
  margin: 0 auto;
}

.india-map {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.india-map img {
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.about_us_recognise {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.about_us_recognise h2 {
  color: #13151e;
  text-align: center;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
  margin: 0;
  position: relative;
  padding: 0 30px;
}

.no-items-in-cart {
  margin: 0 20px;
}

.about_us_recognise h2::before,
.about_us_recognise h2::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 405px;
  height: 1px;
  background-color: #ff5b00;
  transform: translateY(-50%);
}

.about_us_recognise h2::before {
  left: 0;
  transform: translate(-100%, -50%);
}

.about_us_recognise h2::after {
  right: 0;
  transform: translate(100%, -50%);
}

.ananta-padmanabu
  .about-us-content:nth-child(1)
  .about-us-main-content
  h3::before {
  content: url("../images/about-us/flag.svg");
  margin-right: 8px;
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: text-bottom;
}

.ananta-padmanabu
  .about-us-content:nth-child(2)
  .about-us-main-content
  h3::before {
  content: url("../images/about-us/templeIcon.svg");
  margin-right: 8px;
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: text-bottom;
}

////////////////////////////////////////

.faq {
  margin-top: 120px;
  margin-bottom: 80px;
}

.faq-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.faq-content h2 {
  color: #13151e;
  text-align: center;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
  margin: 0;
}

.faq-main-content {
  margin-top: 60px;
}

.faq-main-content-2 {
  margin-top: 30px;
}

.faq-main-content-2 h3 {
  font-size: 18px;
  line-height: 30px;
  font-weight: 800;
  margin: 0px 0px 20px 0px;
}

.faq-main-content .Mui-expanded {
  border-radius: 10px !important;
}

.accordion .main-content {
  color: #13151e;
  font-family: "Objectivity";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.accordion .mini-content {
  color: #7c7a85;
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

.accordion .MuiAccordionSummary-root {
  padding: 8px 16px !important;
  margin: 15px 0;
}

// .accordion .MuiAccordion-gutters:first-of-type {
//   border-top-right-radius: 10px !important;
//   border-top-left-radius: 10px !important;
// }

// .accordion .MuiAccordion-gutters.Mui-expanded .main-content {
//   color: #ff5b00 !important;
// }

.accordion .MuiAccordionSummary-expandIconWrapper {
  color: #ff5b00 !important;
}

.accordion .MuiAccordion-rounded {
  box-shadow: 0px 8px 24px 0px rgba(140, 149, 159, 0.2) !important;
}

.footer {
  padding: 50px 0px;
  background: #fef9f3;
  position: relative;
  z-index: 111;
  border-top: 1px solid #e8e8e8;
  overflow: hidden;
}

.footer-bg img {
  width: 530px;
  object-fit: cover;
}

.footer-bg {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -111;
}

.footer-logo-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}

.footer-logo-content p {
  color: rgba(19, 21, 30, 0.85);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin: 0;
  width: 650px;
}

.footer-social-icons {
  margin-top: 20px;
}

.footer-social-icons ul {
  display: flex;
  padding: 0;
  margin: 0;
}

.footer-social-icons ul li {
  list-style: none;
}

.footer-social-icons ul li a img {
  width: 30px;
  height: 30px;
}

.footer-social-icons ul li:not(:first-child) {
  margin-left: 20px;
}

// vishal
.footer-main-content {
  position: relative;
}

// .footer-main-content::after {
.footer .om_footer::after {
  content: "";
  position: absolute;
  bottom: 20px;
  right: 150px;
  width: 300px;
  height: 300px;
  background-image: url("../images/footer/om_large.svg");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  // filter: brightness(50%);
}

.get-to-know p {
  color: #13151e;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.5px;
  margin: 0;
}

.get-to-know ul {
  padding: 0;
  margin: 20px 0px 0px 0px;
}

.get-to-know ul li {
  margin-bottom: 10px;
  list-style: none;
}

.get-to-know ul li Button {
  color: rgba(19, 21, 30, 0.85);
  font-family: "Inter";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  text-decoration: none;
  text-transform: unset !important;
}

.get-to-know ul li a:-webkit-any-link {
  color: rgba(19, 21, 30, 0.85);
  font-family: "Inter";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  text-decoration: none;
}

.get-to-know ul li a:-webkit-any-link:hover {
  color: #ff5b00;
}

.get-to-know-2 ul li {
  color: rgba(19, 21, 30, 0.85);
  font-family: "Inter";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.sub-footer {
  background: #ffeee4;
  padding: 20px 0px;
}

.sub-footer-content p {
  font-family: "Manrope";
  color: #13151e;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  text-align: center;
}

.bookseva-lorem {
  background-color: #fff;
  padding: 120px 0px;
}

.bookseva-lorem-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bookseva-lorem-content h2 {
  color: #24292e;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  margin: 0;
}

.bookseva-lorem-content Button {
  margin-top: 20px !important;
}

// PROFILE START
.profile {
  margin: 100px 0px 40px 0px;
  padding: 40px 0px;
}

.profile-icon img {
  width: 20px !important;
  margin-right: 10px;
}

.profile-content {
  width: 100%;
  display: flex;
}

.profile-menu {
  border-radius: 10px;
  background: #fff;
  width: 20%;
  height: autopx;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
  position: relative;
}

.profile-menu-content img {
  width: 100px;
  border-radius: 50%;
}
// .img-edit-f{
//   color: rgba(0, 0, 0, 0);
// }
// .img-edit{
//   z-index: -10;
//   color: rgba(0, 0, 0, 0);

// }
.img-edit:hover {
  // background-color: rgba(0,0,0,0.5);
  // color:white;
  cursor: pointer;
}
._8dah {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile-menu-content h4 {
  color: #2c2c2c;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px 0px 10px 0px;
}

.profile-menu-content h5 {
  color: #2c2c2c;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px 0px 10px 0px;
}

.profile-menu-content p {
  color: rgba(44, 44, 44, 0.6);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
}

.my-profile-info {
  margin: 30px 0px 40px 0px;
}

.my-profile-info .MuiAccordion-rounded {
  background-color: unset !important;
  box-shadow: unset !important;
  margin: 0px !important;
}

.my-profile-info .MuiAccordion-rounded::before {
  background-color: unset !important;
}

.my-profile-info .MuiAccordion-rounded #panel1a-header,
.my-profile-info .MuiAccordion-rounded #panel2a-header {
  min-height: 0px !important;
  padding-left: 0px !important;
}

.my-profile-info
  .MuiAccordion-rounded
  #panel1a-content
  .MuiAccordionDetails-root {
  padding-bottom: 0px !important;
}

.my-profile-info .MuiAccordion-rounded #panel1a-header p {
  color: #686868;
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
}

.my-profile-info .MuiAccordion-rounded #panel1a-header p:hover,
.my-profile-info .MuiAccordion-rounded #panel2a-header p:hover {
  color: #ff5b00;
}

.my-profile-info .MuiAccordion-rounded #panel2a-header p {
  color: #686868;
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin: 10px 0px 0px 0px;
}

.my-profile-info .MuiAccordion-rounded .MuiAccordionSummary-contentGutters {
  margin: 0px !important;
}

.my-profile-info
  .MuiAccordion-rounded
  #panel1a-content
  .MuiAccordionDetails-root
  p {
  color: #686868;
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin: 0px 0px 10px 0px;
  display: flex;
  align-items: center;
}

.my-profile-info
  .MuiAccordion-rounded
  #panel1a-content
  .MuiAccordionDetails-root
  p:hover {
  color: #ff5b00;
}

.profile-menu {
  position: relative;
}

.profile-menu::after {
  content: "";
  position: absolute;
  top: 0;
  right: -20px;
  bottom: 0;
  width: 2px;
  background-color: #d9d9d9;
}

.menu-icon span img {
  width: 20px !important;
  margin-right: 10px;
}

.profile-log-out-btn {
  position: absolute;
  bottom: 0;
  left: 22%;
}

.profile-log-out-btn Button {
  color: #f22;
  font-family: "Objectivity";
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  text-transform: unset !important;
  position: relative;
}

.profile-log-out-btn Button::before {
  content: "";
  position: absolute;
  background-image: url("../images/log-outicon.svg");
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  left: -20px;
  top: 5px;
}

.profile-information {
  width: 80%;
  padding: 20px 40px;
}

.profile-information h2 {
  color: #2c2c2c;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.profile-information-box {
  border-radius: 20px;
  background: #fff;
  margin-top: 30px;
  padding: 20px;
  width: 880px;
  position: relative;
}

.profile-information .profile-information-box:before {
  position: absolute;
  content: "";
  background-image: url("../images/address/Temple.svg");
  background-repeat: no-repeat;
  bottom: 0;
  right: 0;
  width: 100px;
  height: 100px;
}

.personal-information {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.personal-information-content h2 {
  color: var(--Color---Grey-2, #4f4f4f);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.4px;
  margin: 0;
}

.personal-information.addr-flex-disable .personal-information-content h2 {
  display: inline-block;
  position: relative;
  padding-right: 25px;
}

.personal-information.addr-flex-disable
  .personal-information-content
  h2::after {
  content: "";
  display: block;
  width: 28vw;
  height: 1px;
  background-color: #ff5b00;
  position: absolute;
  top: 40%;
  left: 100%;
  transform: translateY(-50%);
}

.edit-btn Button:hover {
  box-shadow: unset;
  background-color: unset;
}

.edit-btn Button {
  color: #ff5b00;
  font-family: "Objectivity";
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: unset;
  position: relative;
  border: 0.6px solid rgba(255, 91, 0, 0.4);
  border-radius: 20px;
  padding: 10px 40px;
  margin-left: 10px;
}

.edit-btn Button::after {
  content: "";
  position: absolute;
  background-image: url("../images/edit.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 13px;
  height: 13px;
  right: 12px;
  top: 8px;
}

.edit-btn .save::after {
  content: "";
  width: 0px;
}

.edit-btn .cancel::after {
  content: "";
  position: absolute;
  background-image: url("../images/profile/delete.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 13px;
  height: 13px;
  right: 12px;
  top: 8px;
}

.form-group {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.form-group label {
  color: #242424;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings:
    "salt" on,
    "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;
}

.form-group input {
  border-radius: 33px;
  border: 0.6px solid var(--Text---Secondary-Color, #676767);
  padding: 13px 10px;
  color: #828282;
  font-family: "Objectivity";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.form-group input:focus-visible {
  outline: unset;
}

.form-group input::placeholder {
  color: #242424;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.12px;
}

.date-picker-form input {
  border-radius: unset !important;
  border: unset;
}

.date-picker-form .MuiOutlinedInput-notchedOutline {
  border-radius: 33px;
  border: 0.6px solid var(--Text---Secondary-Color, #676767);
}

.gender-radio .radio-button {
  margin: 10px 0;
}

.gender-radio .radio-button input[type="radio"] {
  display: none;
}

.gender-radio .radio-button label {
  width: 100%;
  background-color: #dadada;
  padding: 15px 40px;
  margin: 0;
  border-radius: 50px;
  cursor: pointer;
}

.gender-radio .radio-button input[type="radio"]:checked + label {
  background: #ff5b00;
  color: #fafaf4;
  border-radius: 50px;
}

.gender-radio input[type="checkbox"] + label,
input[type="radio"] + label {
  margin: 0;
}

.gender-radio {
  display: flex;
  justify-content: space-between;
}

.dropbtn {
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  background-color: unset;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  font-family: "Objectivity";
  padding: 12px 30px;
  color: #686868;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  display: block;
  white-space: nowrap;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

// PROFILE END

// ADDRESS START
.search-bar input {
  width: 100%;
  border-radius: 50px;
  height: 55px;
  border: 0.6px solid rgba(255, 91, 0, 0.3);
  color: rgba(80, 80, 80, 0.75);
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 40px;
}

.search-bar input:focus-visible {
  outline: unset !important;
}

.search-bar input::placeholder {
  color: rgba(80, 80, 80, 0.75);
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.search-box-title {
  margin-top: 30px;
}

.search-box-title h3 {
  color: #2c2c2c;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin: 0;
}

.search-box-title h3 span {
  color: rgba(44, 44, 44, 0.4);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.search-box-mini-content img {
  width: 120px;
}

.search-box {
  margin: 20px 0px;
  width: 100%;
  border: 0.6px solid rgba(255, 91, 0, 0.4);
  border-radius: 10px;
  padding: 10px;
}

.search-border-box {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.search-box-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-box-mini {
  display: flex;
}

.search-box-mini .deliver-date {
  margin-left: 20px;
}

.search-box-mini .deliver-date h3 {
  color: #00ad00;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 0px 0px 5px 0px;
}

.search-box-mini .deliver-date h4 {
  color: #2c2c2c;
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin: 0px 0px 5px 0px;
}

.search-box-mini .deliver-date p {
  color: rgba(44, 44, 44, 0.75);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin: 0;
}

.search-bar {
  position: relative;
}

.search-btn-box {
  position: absolute;
  top: 13px;
  left: 11px;
}

.search-bar .search-order {
  position: absolute;
  right: 5px;
  top: 5px;
}

.search-box-mini .deliver-date p span {
  color: #ff5b00;
  font-weight: 700;
}

.search-order .search-btn {
  border-radius: 40.87px;
  background: hwb(21 0% 0%);
  color: #fff;
  font-family: "Objectivity";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: unset;
  padding: 15px 30px;
}

.search-order .search-btn:hover {
  background: hwb(21 0% 0%);
}

// ADDRESS END

// EXPLORE PUJA
.categories {
  margin-top: 120px;
}

.categories-content h2 {
  color: #2c2c2c;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.categories-gallery {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.categories-gallery-css {
  display: flex;
  gap: 10px;
}

.categories-gallery-content {
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  text-transform: unset !important;
  border-radius: 10px !important;
  background-color: unset !important;
  color: #ff5b00 !important;
}

.categories-gallery-content span {
  color: #ff5b00;
  font-family: "Objectivity" !important;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 10px;
}

.app-new-categories-gallery-content span {
  color: #ff5b00;
}

.categories-gallery-content.selected {
  border: 1px solid rgb(238, 98, 70) !important;
}

.categories-tabs {
  margin-top: 50px;
  margin-bottom: 180px;
}

.MuiTab-textColorPrimary {
  color: #b5b8bf !important;
  font-family: "Objectivity" !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 15px !important;
  text-transform: unset !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #ff5b00 !important;
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  text-transform: unset !important;
}

.MuiTabs-indicator {
  background-color: #ff5b00 !important;
}

#EXPLORE_BG {
  position: relative;
  padding: 90px 0px 0px 0px;
}

#EXPLORE_BG .nama-bg {
  position: absolute;
  background-image: url("../images/devasmriti-digitally/nama.svg");
  width: 300px;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  bottom: 0;
  right: 0;
  z-index: -999;
}
#EXPLORE_BG .nava-chandi-bg p {
  min-height: 0px;
}

.descripition-tabs-2-content {
  margin-bottom: 20px;
}

.descripition-tabs-2-content h4 {
  color: #ff5b00 !important;
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  margin: 0;
}

.description-content {
  margin-top: 20px;
}

.description-content h3 {
  font-size: 18px;
  line-height: 27px;
  font-weight: 700;
  margin: 0px 0px 10px 0px;
}

.descripition-tabs-2-content .description-content {
  margin-top: 20px;
}

.description-tabs-2 {
  display: none;
}

// INDIVIDUAL START

#IndividualBanner {
  width: 100%;
  height: 100%;
  background-color: #fef9f3;
}

.custom-choose-seva .MuiList-root {
  border: 1px solid #c9c9c9;
  background-color: white;
}

.custom-choose-seva .MuiList-root li {
  border-radius: 12px;
  background-color: white;
}

.individual-image {
  position: relative;
  border-radius: 10px;
  // height: 100%;
  height: 442px;
}

.individual-image:before {
  position: absolute;
  content: "";
  // background: linear-gradient(96.65deg, rgba(0, 0, 0, 0.6) 16.27%, rgba(0, 0, 0, 0) 62.01%);
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.individual-image .individual-image-small {
  background-color: white;
  // z-index: 99;
  position: absolute;
  width: 51px;
  height: 50px;
  bottom: 30px;
  left: 22px;
  border-radius: 50px;
  display: inline-block;
}

.individual-image .individual-image-small:before {
  position: absolute;
  content: "";
  width: 25px;
  height: 25px;
  background-size: cover;
  bottom: 12px;
  left: 10px;
  // background-image: url('../images/banner/share.svg');
  // background-repeat: no-repeat;
  // z-index: 9999;
}

.individual-image .speeddeal {
  position: absolute;
  bottom: 10%;
  left: 18%;
}

.individual-image img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.individual-content {
  position: relative;
  z-index: 99;
}

.individual-content h2 {
  color: #2c2c2c;
  font-family: "Objectivity";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin: 0px 0px 20px 0px;
}

.individual-content h5 {
  color: #2c2c2c;
  font-family: "Objectivity";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px 0px 20px 0px;
}

.individual-content h6 {
  color: #505050;
  font-family: "Objectivity";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px 0px 15px 20px;
}

.individual-content h6 span {
  color: #ff5b00;
  font-family: "Objectivity";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  // margin: 0px 10px;
  display: inline-block;
  width: 68px;
  position: relative;
}

.individual-content h6 .event::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-image: url("../images/champaign/calendar-search.svg");
  background-repeat: no-repeat;
  background-size: cover;
  top: -03px;
  left: -30px;
  pointer-events: none;
}

.individual-content h6 .venue::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-image: url("../images/champaign/venue.svg");
  background-repeat: no-repeat;
  background-size: cover;
  top: -05px;
  left: -30px;
}

.choose-seva {
  margin-top: 15px;
}

// .choose-seva-content {
//   width: 330px;
// }

.choose-seva-content label {
  color: #7e91ae;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings:
    "salt" on,
    "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.choose-seva-content .MuiOutlinedInput-notchedOutline {
  border-radius: 50px;
}

.choose-seva-content .custom-choose-seva .MuiOutlinedInput-notchedOutline {
  border-radius: 13px;
}

.choose-seva-content {
  padding: unset !important;
}
.choose-seva-select {
  margin-top: 0px !important;
}
.choose-seva-content .choose-seva-select #demo-simple-select-label {
  color: #242424;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}
.choose-seva-cost {
  margin-top: 30px;
}

.choose-seva-cost h2 {
  color: #222;
  leading-trim: both;
  text-edge: cap;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  margin: 0;
}

.choose-seva-booking-cost {
  margin-top: 25px;
}

.choose-seva-booking-cost Button {
  // border-radius: 50px;
  border: 1px solid #ff5b00;
  // padding: 12px 20px;
  color: #ff5b00;
  font-family: "Objectivity";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: unset;
}

// .choose-seva-booking-cost Button:not(:first-child):hover{
//     background-color: unset !important;
// }
.choose-seva-booking-cost Button span {
  margin-right: 10px;
}

.choose-seva-booking-cost Button span img {
  width: 15px;
}

.choose-seva-booking-cost .bookseva {
  color: #fff;
  font-family: "Objectivity";
  background-color: #ff5b00;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
  padding: 12px 15px;
}

.choose-seva-booking-cost .bookseva:hover {
  color: #fff;
  font-family: "Objectivity";
  background-color: #ff5b00;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 12px 15px;
}

.choose-seva-booking-cost .bookseva span {
  margin: 0px 0px 0px 10px;
}

.champaign-sev-select-box > p > h3,
.champaign-sev-select-box > p > p {
  display: none;
}

.champaign-sev-select-box > p > ul {
  padding-left: unset;
}

.champaign-sev-box-content > .choose-seva {
  display: none;
}

.free-shipping {
  margin-top: 30px;
  width: 450px;
  display: flex;
  justify-content: center;
}

.free-shipping p {
  color: #505050;
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  text-align: center;
}

.thila-homam {
  padding: 220px 0px 100px 0px;
}

.thila-homam > h3 {
  font-family: Objectivity;
  font-size: 22px;
  font-weight: 500;
  line-height: 24.6px;
  letter-spacing: 0.03em;
  text-align: left;
  margin-bottom: 10px;
}

.description-tabs {
  margin: 50px 0px 0px 0px;
}

.choose-seva-content #demo-simple-select {
  padding: 10px !important;
}

.choose-seva-content .MuiFormControl-root {
  margin: 5px 0px 0px 0px !important;
}

.choose-seva-content .MuiFormControl-root em {
  color: #242424;
  font-family: "Objectivity";
  font-size: 14px;
  font-style: normal !important;
  font-weight: 700;
  line-height: normal;
}

.choose-seva-content .MuiPaper-elevation .MuiList-padding .MuiMenuItem-gutters {
  color: #242424;
  font-family: "Objectivity";
  font-size: 14px;
  font-style: normal !important;
  font-weight: 700;
  line-height: normal;
}

.choose-seva-content .MuiFormControl-root .MuiOutlinedInput-input {
  color: #242424;
  font-family: "Objectivity";
  font-size: 14px;
  font-style: normal !important;
  font-weight: 700;
}

.choose-seva-content #demo-simple-select .MuiPaper-elevation8 .MuiMenu-list {
  padding: 0px !important;
}

.app-new-individual-suggested-puja {
  margin-bottom: 30px;
  margin-top: 0px !important;
}

.app-new-individual-suggested-puja-2 {
  margin-top: 30px !important;
}

.description-content p {
  color: rgba(19, 21, 30, 0.85);
  font-family: "Objectivity";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: 0px 0px 05px 0px;
}

#simple-tabpanel-0 .MuiBox-root,
#simple-tabpanel-1 .MuiBox-root,
#simple-tabpanel-2 .MuiBox-root,
#simple-tabpanel-3 .MuiBox-root {
  padding: 24px 0px !important;
}

// INDIVIDUAL END

// CART START
.add-to-cart {
  margin: 50px 0px;
}

.app-new-add-to-cart .add-to-cart-box-mini-content {
  margin-top: 0px;
}

.app-new-add-to-cart .add-to-cart-price h4 {
  font-size: 18px;
}

.app-new-add-to-cart {
  padding: 120px 0px 60px 0px;
}

.add-to-cart-22 {
  padding: 100px 0px 120px 0px;
}

.add-to-cart-2 {
  padding: 100px 0px 120px 0px;
}

.add-to-cart-title h2 {
  color: #2c2c2c;
  font-family: "Objectivity";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 20px;
}

.add-to-cart-content {
  margin-top: 20px;
}

.add-to-cart-box {
  border-radius: 15px;
  background: #fff;
  padding: 20px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
}
.add-to-cart-box:not(:first-child) {
  margin-top: 20px;
}

.add-to-cart-box-main-content {
  display: flex;
}

.add-to-cart-box-mini-content {
  margin-left: 30px;
  flex-grow: 1;
  // margin-top: 20px;
}

.add-to-cart-box-mini-content h4 {
  color: #2c2c2c;
  font-family: "Objectivity";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin: 0px 0px 5px 0px;
}
.add-to-cart-box-main-content p {
  color: #2c2c2c;
  font-family: "Objectivity";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  margin: 10px 0px;
}

.add-to-cart-box-mini-content h5 {
  color: rgba(44, 44, 44, 0.75);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px 0px 5px 0px !important;
}

.add-to-cart-box-mini-content h6 {
  color: #747474;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px 0px 0px 0px;
}

// vishal

.add-to-cart-child-content-my-cart {
  background: #fff;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin: 0 20px;
}

.add-to-cart-box-main-content .cart-image {
  width: 200px;
  min-width: 200px;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}

.preference-form {
  margin-top: 25px;
}

.app-new-preference-form {
  margin-top: 70px;
}

.preference-form form {
  display: flex;
  align-items: center;
}

.preference-form label {
  color: var(--Text---Secondary-Color, #676767);
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 5px;
}

.preference-form input {
  width: 15px;
  height: 15px;
}

.add-to-cart-price h4 {
  color: #ff5b00;
  leading-trim: both;
  text-edge: cap;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 21.6px;
  margin: 0;
}

.add-to-cart-box-content {
  display: flex;
  justify-content: space-between;
  gap: 6px;
}

.app-new-add-to-cart-box-content {
  margin-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 20px;
}

.add-cart-price-remove-btn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
._0plu {
  background: #ff5b00 !important;
  border-radius: 40.87px !important;
  border: #ff5b00 !important;
  color: #fff !important;
  font-family: Objectivity !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  line-height: 25px !important;
  padding: 10px 40px !important;
  text-transform: inherit !important;
}

._0plu1 {
  background: #ff5b00 !important;
  border-radius: 15px !important;
  border: #ff5b00 !important;
  color: #fff !important;
  font-family: Objectivity !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  line-height: 25px !important;
  padding: 6px 40px !important;
  text-transform: inherit !important;
}

.add-to-card-seva-content-2 .app-new-remove-btn {
  margin-top: 20px;
}

.remove-btn Button {
  color: #2c2c2c;
  font-family: "Objectivity";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.36px;
  text-decoration-line: underline;
}

.order-summary {
  border-radius: 15px;
  background: #fff;
  padding: 20px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
}

.order-summary-title h2 {
  color: #2c2c2c;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin: 0;
}

.order-summary-list {
  margin-top: 30px;
}

.order-summary-list ul {
  padding: 0;
  margin: 0px 0px 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-summary-list ul li {
  list-style: none;
  color: #505050;
  text-align: center;
  font-size: 15.326px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.order-summary-list ul li:not(:first-child) {
  color: #242424;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.proceed-to-pay {
  display: flex;
  justify-content: center;
}

.coupons {
  margin-bottom: 40px;
}

.coupons-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.coupons-content-title h4 {
  color: var(--Color---Grey-2, #4f4f4f);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.4px;
  margin: 0;
}

.coupons-btn Button {
  border-radius: 40.87px;
  display: flex;
  align-items: center;
  color: #ff5b00;
  font-family: "Objectivity";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: unset;
}

.coupons-main-btns {
  margin-top: 10px;
}

.coupons-main-btns .coupon-name {
  border-radius: 33px;
  border: 0.6px solid var(--Text---Secondary-Color, #676767);
  padding: 15px 50px;
  color: #242424;
  font-family: "Objectivity";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: unset;
}

.coupons-main-btns Button {
  border-radius: 33px;
  border: 1.277px solid #ff5b00;
  padding: 15px 40px;
  color: #242424;
  font-family: "Objectivity";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: unset;
  margin-left: 10px;
}

// CART END

// Invoice page

.invoice-box button:hover {
  background-color: #ff5b00;
}

.cust-invoice-image {
  width: 250px;
}

.invoice-box-child {
  position: relative;
  overflow: hidden;
}

.invoice-box .invoice-box-child:before {
  position: absolute;
  content: "";
  width: 501px;
  height: 501px;
  background-size: contain;
  background-position: bottom left;
  background-image: url("../images/address/Temple.svg");
  left: -196px;
  bottom: -120px;
  background-repeat: no-repeat;
}

.cust-invoice-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// / written in this extra added styles

.add-to-cart-box {
  border-radius: 15px;
  background: #fff;
  padding: 20px;
}

.add-to-cart-box-my-cart {
  padding: 20px;
  position: relative;
}

.add-to-cart-box-my-cart:not(:first-child)::before {
  position: relative;
  border-radius: 4px;
}

.add-to-cart-box-my-cart:not(:first-child)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  height: 0.3px;
  background-color: #676767;
}

.add-to-cart-box h5 {
  color: #2c2c2c;
  font-family: "Objectivity";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 10px;
}

.add-to-cart-box h5 span {
  color: rgba(44, 44, 44, 0.4);
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.add-to-cart-box-2 {
  display: flex;
  margin-bottom: 30px;
}

.add-to-card-seva {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.add-to-card-seva-content {
  margin-left: 20px;
}

.add-to-card-seva-content h4 {
  color: #2c2c2c;
  font-family: "Objectivity";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 10px;
}

.add-to-card-seva-content h6 {
  color: rgba(44, 44, 44, 0.75);
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}

.add-to-card-seva-content h5 {
  color: #505050;
  font-family: "Objectivity";
  font-size: 15.326px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}

.add-to-card-seva-content h5 span {
  color: #ff5b00;
  font-family: "Objectivity";
  font-size: 15.326px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.46px;
}

.add-to-card-seva-content p {
  color: var(--Text---Secondary-Color, #676767);
  font-family: "Objectivity";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  // margin-top: 74px;
  display: flex;
  align-items: center;
}

.add-to-card-seva-content .MuiCheckbox-colorPrimary {
  padding: 0px 10px 0px 0px;
}

.add-to-card-seva-content p .css-j204z7-MuiFormControlLabel-root {
  margin: 0px;
  border: 2px solid #000000;
}

.add-to-card-seva-content-2 p:nth-child(1) {
  color: #ff5b00;
  font-family: "Objectivity";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 21.6px;
}

.add-to-card-seva-content-2 p:nth-child(2) {
  color: #2c2c2c;
  font-family: "Objectivity";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 116px;
}

.add-to-card-seva-content-2 h4 {
  color: #ff5b00;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px 0px 0px 0px;
}

.MuiCheckbox-colorPrimary {
  color: #ff5b00 !important;
}

.add-family-details {
  margin-top: 30px;
  border-top: 1px solid #00000033;
}

.add-family-details-1 {
  margin-bottom: 30px;
}

.add-family-details-1 h5 {
  color: var(--Color---Grey-2, #4f4f4f);
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.4px;
  margin: 20px 0px;
}

.add-family-details-2 {
  border-top: 1px solid #00000033;
}

.add-family-details-box-2 {
  margin-top: 30px;
  border: 0.6px solid #ff5b0066;
  padding: 15px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-family-details h4 {
  color: var(--Color---Grey-2, #4f4f4f);
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.4px;
  margin: 25px 0px 25px 0px;
}

.add-family-details-box {
  border: 0.6px solid #ff5b0066;
  padding: 15px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-family-details-box-1 {
  border: 0.6px solid #ff5b0066;
  padding: 15px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.add-family-details-box-text h6 {
  color: var(--Color---Grey-2, #4f4f4f);
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.4px;
  margin-bottom: 5px;
}

.add-family-details-box-text p {
  color: #505050;
  text-align: center;
  font-family: "Objectivity";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.add-family-details-box .MuiSvgIcon-root {
  color: #ff5b00;
}

.add-family-details-box-2 .MuiSvgIcon-root {
  color: #ff5b00;
}

.add-family-details-btn {
  margin-top: 30px;
}

.add-family-details-btn button {
  border-radius: 40.87px;
  background: #ff5b00;
  width: 100%;
  color: #fff;
  font-family: "Objectivity";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  padding: 10px 0px;
}

.add-family-details-btn button .MuiSvgIcon-root:nth-child(1) {
  margin-right: 10px;
}

.add-family-details-btn button .MuiSvgIcon-root:nth-child(2) {
  font-size: 28px;
  margin-left: 15px;
}

.add-family-details-btn button:hover {
  color: #fff;
  background: #ff5b00;
}

// FAMILY START

.personal-information-content {
  display: flex;
  align-items: center;
}

.personal-information-content-2 h2 {
  margin-left: 10px;
}

.relation-content {
  margin: 20px 0px 0px 40px;
}

.relation-content .relation h5 {
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  color: #ff5b00;
  margin: 0px 0px 10px 0px;
}

.relation-content .relation p {
  color: #24292e;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
}

.relation-content .relation p span {
  color: rgba(36, 41, 46, 0.75);
}

/// custom scrollbar

// .myfamily-forms-2 .MuiMenu-paper {
//   max-height: 250px;
//   overflow: auto;
// }

// .relation.cust-addr-flex p {
//   margin: 0;
//   position: relative;
//   padding-left: 40px; /* Adjust this value based on the image width */
// }

// .relation.cust-addr-flex p::before {
//   content: "";
//   display: block;
//   background-image: url('../images/address/person_icon.svg');
//   background-size: contain;
//   background-repeat: no-repeat;
//   width: 30px;
//   height: 30px;
//   position: absolute;
//   left: 0;
//   top: 50%;
//   transform: translateY(-50%);
// }

.myfamily-forms-2 .MuiMenu-list {
  scrollbar-width: thin;
  scrollbar-color: #ff5b00 #fff;
}

.myfamily-forms-2 .MuiMenu-list::-webkit-scrollbar {
  width: 8px;
}

.myfamily-forms-2 .MuiMenu-list::-webkit-scrollbar-track {
  background: #fff;
}

.myfamily-forms-2 .MuiMenu-list::-webkit-scrollbar-thumb {
  background-color: #ff5b00;
  border-radius: 10px;
  border: 2px solid #fff;
}

////////////////////

.addmore-btn-content {
  width: 900px;
  margin: 40px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.addmore-btn-content p {
  color: rgba(79, 79, 79, 0.65);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.4px;
  margin: 0;
}

.addmore-btn-content Button {
  border: 1px solid #ff5b00;
  border-radius: 40px;
  padding: 10px 30px;
  color: #ff5b00;
  font-family: "Objectivity";
  font-size: 15px;
  text-transform: unset !important;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.personal-information-content img {
  display: block;
  width: 50px;
}

//
.personal-information-form {
  margin-top: 30px;
}

.myfamily-forms {
  display: flex;
  flex-direction: column;
}

.myfamily-forms-2 .MuiPopover-paper {
  min-width: 357px !important;
  min-height: 387px !important;
  border-radius: 10px !important;
  border: 0.6px solid var(--Text---Secondary-Color, #676767) !important;
  background: #fff !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  color: #ff5b00;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-family: "Objectivity" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root span {
  color: #ff5b00 !important;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background-color: unset;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: unset;
}

.myfamily-forms .css-u4tvz2-MuiFormLabel-root {
  margin-bottom: 10px;
  color: #7e91ae;
  font-family: "Objectivity";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.myfamily-forms-1 .css-u4tvz2-MuiFormLabel-root {
  margin-bottom: 10px;
  color: #7e91ae;
  font-family: "Objectivity";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 171.429% */
}

.myfamily-forms-btn {
  margin-top: 40px;
}

.myfamily-forms-btn button:first-child {
  border-radius: 40.87px;
  border: 1.277px solid #ff5b00;
  color: #ff5b00;
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 12px 36px;
}

.myfamily-forms-btn button:nth-child(2) {
  border-radius: 40.87px;
  background: #ff5b00;
  color: #fff;
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  padding: 15px 30px;
  margin-left: 25px;
}

.myfamily-forms-1 .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 33px;
  color: var(--Color---Grey-1, #828282);
  height: 42px;
  font-family: "Objectivity";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.myfamily-forms-1 .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px;
}

.myfamily-forms-1
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border-radius: 33px;
  color: var(--Color---Grey-1, #828282);
  height: 42px;
  font-family: "Objectivity";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.myfamily-forms-1 {
  display: flex;
  flex-direction: column;
}

.myfamily-forms-1 .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 33px;
  color: var(--Color---Grey-1, #828282);
  height: 42px;
  font-family: "Objectivity";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.myfamily-forms-2 {
  display: flex;
  flex-direction: column;
}

.myfamily-forms-2 .css-u4tvz2-MuiFormLabel-root {
  margin-bottom: 10px;
  color: #7e91ae;
  font-family: "Objectivity";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 171.429% */
}

.myfamily-forms-2
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border-radius: 33px;
  color: var(--Color---Grey-1, #828282);
  height: 42px;
  font-family: "Objectivity";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.myfamily-forms-1 .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 10px;
  color: var(--Color---Grey-1, #828282);
  font-family: "Objectivity";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:focus {
  outline: none;
}

.css-vh2xoj-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #c9c9c9 !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px;
}

// stepper //
.top-stepper {
  margin: 50px 0px 70px 0px;
}

.top-stepper .MuiStepIcon-root.Mui-active {
  color: #ff5b00;
}

.top-stepper .MuiStepIcon-root.Mui-completed {
  color: #ff5b00;
}

.top-stepper .MuiStepConnector-horizontal .MuiStepConnector-line {
  display: block !important;
  border: 1.8px solid #ff5b00 !important;
}

.top-stepper .MuiStep-root .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  color: var(--Color---Grey-2, #4f4f4f);
  font-family: "Objectivity";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.4px;
  /* 160% */
  margin-top: 20px;
}

.top-stepper .MuiStep-root .MuiSvgIcon-root-MuiStepIcon-root {
  font-size: 30px !important;
}

.top-stepper .MuiStep-root .MuiStepLabel-root {
  cursor: pointer !important;
}

.top-stepper .MuiStep-root .MuiSvgIcon-root-MuiStepIcon-root {
  color: #fff !important;
  border-radius: 50% !important;
  border: 1px solid #ff5b00 !important;
}

.top-stepper .MuiStepLabel-iconContainer .css-117w1su-MuiStepIcon-text {
  color: #ff5b00;
  font-family: "Objectivity";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

// stepper end//

//billing start

.billing-box {
  // margin-top: 50px;
  border-top: 1px solid #00000033;
  border-bottom: 1px solid #00000033;
}

.billing-forms {
  margin-bottom: 40px;
}

.billing-box h6 {
  color: var(--Color---Grey-2, #4f4f4f);
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.4px;
  /* 140% */
  margin: 15px 0px 20px 0px;
}

.billing-content-link p {
  color: rgba(36, 41, 46, 0.6);
  font-family: "Objectivity";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: 30px 0px;
}

.billing-content-link a {
  color: #ff5b00;
  font-family: "Objectivity";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
}

.add-h-line {
  display: flex;
  position: relative;
  width: 100%;
}

.add-h-line::after {
  content: "";
  display: flex;
  flex-grow: 1;
  width: 70%;
  height: 1px;
  background-color: #ff5b00;
  position: absolute;
  top: 50%;
  left: 150px;
  transform: translateY(-50%);
}

.profile-information-box .billing-box {
  border: unset !important;
}

//billing end

//delivery start
.delivery-options {
  border-top: 1px solid #00000033;
  border-bottom: 1px solid #00000033;
  padding: 40px 0px;
}

.delivery-options-content h6 {
  color: var(--Color---Grey-2, #4f4f4f);
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.4px;
  margin-bottom: 20px;
}

.delivery-options-content-box {
  width: 410px;
  display: flex;
  justify-content: space-between;
}

.delivery-options-content-box-1 {
  width: 187px;
}

.delivery-options-content-box-1 h5 {
  color: #ff5b00;
  font-family: "Objectivity";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}

.delivery-options-content-box-1 p {
  color: rgba(36, 41, 46, 0.6);
  font-family: "Objectivity";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.edit-btn-1 {
  margin-top: 30px;
}

.edit-btn-1 Button {
  color: #ff5b00;
  font-family: "Objectivity";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: unset;
  position: relative;
  border: 0.6px solid #ff5b0066;
  padding: 7px 35px 7px 27px;
  border-radius: 40px;
}

.edit-btn-1 Button::after {
  content: "";
  position: absolute;
  background-image: url("../images//edit.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 13px;
  height: 13px;
  right: 13px;
  top: 6px;
}

//delivery end

// FAMILY START
.personal-information-form {
  margin-top: 30px;
}

.myfamily-forms {
  display: flex;
  flex-direction: column;
}

.MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  width: 357px;
  height: 387px;
  border-radius: 10px;
  border: 0.6px solid var(--Text---Secondary-Color, #676767);
  background: #fff;
}

.MuiButtonBase-root-MuiMenuItem-root:hover {
  color: #ff5b00;
}

.MuiPaper-root-MuiPopover-paper-MuiMenu-paper
  .MuiButtonBase-root-MuiMenuItem-root:hover {
  background-color: unset;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: unset;
}

.myfamily-forms .MuiFormLabel-root {
  margin-bottom: 10px;
  color: #7e91ae;
  font-family: "Objectivity";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.myfamily-forms-1 .MuiFormLabel-root {
  margin-bottom: 10px;
  color: #7e91ae;
  font-family: "Objectivity";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.myfamily-forms-btn {
  margin-top: 40px;
}

.myfamily-forms-btn button:first-child {
  border-radius: 40.87px;
  border: 1.277px solid #ff5b00;
  color: #ff5b00;
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 12px 36px;
}

.myfamily-forms-btn button:nth-child(2) {
  border-radius: 40.87px;
  background: #ff5b00;
  color: #fff;
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  padding: 15px 30px;
  margin-left: 25px;
}

.myfamily-forms-1 .MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 33px;
  color: var(--Color---Grey-1, #828282);
  height: 42px;
  font-family: "Objectivity";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.myfamily-forms-1 .MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px;
}

.myfamily-forms-1 .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border-radius: 33px;
  color: var(--Color---Grey-1, #828282);
  height: 42px;
  font-family: "Objectivity";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.myfamily-forms-1 {
  display: flex;
  flex-direction: column;
}

.myfamily-forms-1 .MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 33px;
  color: var(--Color---Grey-1, #828282);
  height: 42px;
  font-family: "Objectivity";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.myfamily-forms-2 {
  display: flex;
  flex-direction: column;
}

.myfamily-forms-2 .MuiFormLabel-root {
  margin-bottom: 10px;
  color: #7e91ae;
  font-family: "Objectivity";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 171.429% */
}

.myfamily-forms-2 .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border-radius: 33px;
  color: var(--Color---Grey-1, #828282);
  height: 42px;
  font-family: "Objectivity";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.myfamily-forms-2
  .MuiSelect-select
  .css-14bshgw-MuiSvgIcon-root-MuiSelect-icon {
  color: "#FF5B00!important";
}

.myfamily-forms-1 .MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 10px;
  border: 0.6px solid #676767;
  color: var(--Color---Grey-1, #828282);
  font-family: "Objectivity";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.MuiInputBase-input-MuiOutlinedInput-input:focus {
  outline: none;
}

.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px;
}

// FAMILY  END

//
.personal-information-form {
  margin-top: 30px;
}

.myfamily-forms {
  display: flex;
  flex-direction: column;
}

.myfamily-forms .label {
  margin-bottom: 5px;
  color: #7e91ae;
  font-family: "Objectivity";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.myfamily-forms-btn {
  margin-top: 40px;
}

.myfamily-forms-btn button:first-child {
  border-radius: 40.87px;
  border: 1.277px solid #ff5b00;
  color: #ff5b00;
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 12px 36px;
}

.myfamily-forms-btn button:nth-child(2) {
  border-radius: 40.87px;
  background: #ff5b00;
  color: #fff;
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  padding: 15px 30px;
  margin-left: 25px;
}

.myfamily-forms-1 {
  display: flex;
  flex-direction: column;
}

.myfamily-forms-1 label {
  margin-bottom: 5px;
  color: #7e91ae;
  font-family: "Objectivity";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.myfamily-forms-1 .MuiOutlinedInput-notchedOutline {
  border-radius: 33px;
}

.myfamily-forms-1 .MuiInputBase-input.MuiOutlinedInput-input {
  padding: 14px;
  color: var(--Color---Grey-1, #828282);
  font-family: "Objectivity";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  outline: none;
}

.myfamily-forms-1 .MuiInputAdornment-root button .MuiSvgIcon-root {
  color: #ff5b00;
}

.myfamily-forms-2 {
  display: flex;
  flex-direction: column;
}

.myfamily-forms-2 .MuiOutlinedInput-notchedOutline {
  border-radius: 33px;
}

.myfamily-forms-2 .MuiInputBase-input.MuiOutlinedInput-input {
  padding: 14px;
  color: var(--Color---Grey-1, #828282);
  font-family: "Objectivity";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  outline: none;
}

.myfamily-forms-2 .MuiSelect-select {
  padding: 0;
}

.myfamily-forms-2
  .MuiPaper-root-MuiPopover-paper-MuiMenu-paper
  .MuiList-root-MuiMenu-list {
  width: 357px;
  height: 387px;
  border-radius: 10px;
  border: 0.6px solid var(--Text---Secondary-Color, #676767);
  background: #fff;
}

.myfamily-forms-3 {
  display: flex;
  flex-direction: column;
}

.myfamily-forms-3 .MuiOutlinedInput-notchedOutline {
  border-radius: 10px;
}

.myfamily-forms-3 .MuiInputBase-input.MuiOutlinedInput-input {
  color: var(--Color---Grey-1, #828282);
  font-family: "Objectivity";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  outline: none;
}

//
// CHAMPAIGN START

#AppBanner2 {
  width: 100%;
  background-image: url("../images/champaign/champaign.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  position: relative;
  margin-top: 130px;
}

.ananta-padmanabu#ANANTA_BG {
  // margin-top: 30px;
  position: relative;
  z-index: 9;
  height: 100%;
  width: 100%;
}

.ananta-padmanabu {
  // margin-top: 30px;
  position: relative;
  align-self: center;
  z-index: 9;
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin-top: -100px;
}

.ananta-padmanabu-box {
  border-radius: 10px;
  background: #fff;
  padding: 20px;
  width: 100%;
  height: 100%;
  // border: 1px solid #FFB084;
  // background: linear-gradient(180deg, #FFE8DB 0%, #FFFFFF 100%);
  position: relative;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
}

.ananta-padmanabu-box:before {
  content: "";
  position: absolute;
  top: 5px;
  right: 16px;
  width: 100%;
  height: 100%;
  background-image: url("../images/champaign/mandala_pattern.svg");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 155px;
}

.custom-choose-seva .MuiFormControl-root {
  background: white;
  border-radius: 13px;
  width: 100%;
}

.thila-homam .individual-details {
  border: 1px solid #ffb084;
  background: linear-gradient(180deg, #ffe8db 0%, #ffffff 100%);
  position: relative;
  border-radius: 10px;
  padding: 20px !important;
  height: 100%;
}

.thila-homam .individual-details:before {
  content: "";
  position: absolute;
  top: 5px;
  right: 16px;
  width: 100%;
  height: 100%;
  background-image: url("../images/champaign/mandala_pattern.svg");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 199px;
}

.ananta-padmanbu-content h2 {
  color: #242424;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin: 0;
  position: relative;
  z-index: 99;
  margin-bottom: 20px;
  width: 90%;
}

.ananta-mini-content {
  margin: 5px 0px;
}

.ananta-mini-content h4 {
  color: #2c2c2c;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px 0px 10px 0px;
}

.ananta-mini-content h5 {
  color: #505050;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin: 0px 0px 10px 20px;
}

.ananta-mini-content h5 span {
  color: #ff5b00;
  display: inline-block;
  width: 68px;
}

.ananta-mini-content .event {
  position: relative;
}

.ananta-mini-content .event::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-image: url("../images/champaign/calendar-search.svg");
  background-size: cover;
  background-repeat: no-repeat;
  left: -20px;
  top: 3px;
}

.ananta-mini-content .venue {
  position: relative;
}

.ananta-mini-content .venue::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-image: url("../images/champaign/venue.svg");
  background-size: cover;
  background-repeat: no-repeat;
  left: -20px;
  top: 3px;
}

.ananta-padmanbu-btn Button {
  border-radius: 33px;
  background: #ff5b00;
  color: #fff;
  font-family: "Objectivity";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 15px 30px;
  position: absolute;
  right: 28px;
  bottom: 28px;
  width: 200px !important;
}
.ananta-padmanbu-btn1 Button {
  border-radius: 33px;
  background: #ff5b00;
  color: #fff;
  font-family: "Objectivity";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 15px 30px;
  // position: absolute;
  // right:28px;
  // bottom:28px;
  width: 350px !important;
}

.ananta-padmanbu-content .ananta-padmanbu-btn Button {
  margin-top: unset !important;
}

.ananta-padmanbu-mini-content {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // margin-top: 15px;
  margin-top: 7px;
}

.champaign-tab {
  margin-top: 40px;
  margin-bottom: 80px;
}

.champaign-sev-box {
  border-radius: 10px;
  // background: #fff;
  // padding: 10px;
  display: flex;
  justify-content: space-around;
  // align-items: center;
  gap: 20px;
  height: 100%;
}

.champaign-sev-select-box > p > ul li {
  font-size: 13px;
}

.custom-camp-dialog-modal .MuiTypography-root > h3,
.custom-camp-dialog-modal .MuiTypography-root > ul li,
.custom-camp-dialog-modal .MuiTypography-root > p {
  font-family: "Objectivity";
  font-style: normal;
}

.champaign-sev-box > img {
  width: 50%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.champaign-btns {
  display: flex;
}

.champaign-btns Button {
  white-space: nowrap;
}

.champaign-btns Button span {
  margin-right: 5px !important;
  width: 15px;
}

.champaign-sev-box-content {
  // margin-top: 10px;
  width: 50%;
}

.champaign-sev-box-content h4 {
  color: #2c2c2c;
  font-size: 18px;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0px 0px 0px 0px;
}

.champaign-sev-box-content h5 {
  color: #2c2c2c;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin: 0px 0px 10px 0px;
}

.champaign-sev-box-content .price-cost {
  color: #000 !important;
  font-family: "Objectivity";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin: 10px 0px 0px 0px;
}

.champaign-sev-box-content .price-cost span {
  color: #ff5b00 !important;
}

.champaign-sev-box-content .champaign-sev-select-box p {
  color: #505050;
  font-family: "Objectivity";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: 0px;
  // border-top: 1px solid #ddd;
  padding-top: 10px;
}

.champaign-sev-box-content .champaign-sev-select-box p::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Handle */
.champaign-sev-box-content
  .champaign-sev-select-box
  p::-webkit-scrollbar-thumb {
  background-color: #ddd; /* Color of the scrollbar handle */
  border-radius: 2px; /* Border radius for a rounded appearance */
}

/* Track */
.champaign-sev-box-content
  .champaign-sev-select-box
  p::-webkit-scrollbar-track {
  background-color: transparent; /* Color of the scrollbar track */
}

/* Handle on hover */
.champaign-sev-box-content
  .champaign-sev-select-box
  p::-webkit-scrollbar-thumb:hover {
  background-color: #d16121; /* Color of the scrollbar handle on hover */
}

.champaign-sev-select-box .MuiOutlinedInput-notchedOutline {
  border-radius: 50px !important;
  border: 0.6px solid var(--Text---Secondary-Color, #676767);
}

.champaign-sev-select-box .MuiOutlinedInput-input {
  font-family: "Objectivity";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: #242424;
}

.champaign-sev-select-box #demo-simple-select-label {
  top: -5px !important;
}

.champaign-sev-select-box .MuiSelect-icon span {
  color: #ff5b00;
}

.champaign-tab .champaign-btns .bookseva {
  padding: 12px 30px !important;
}

// champaign END

// ABOUT US START

// .about-us-banner {
//   // margin-top: 220px;
//   margin-top: 70px;
//   width: 100%;
//   background-image: url("../images/about-us/hindu-temple.svg");
//   background-repeat: no-repeat;
//   background-size: contain;
//   background-position: center;
//   border-radius: 20px;
// }

.about-us-banner {
  /* margin-top: 220px; */
  margin-top: 70px;
  width: 100%;
  position: relative; /* Ensure positioning context for pseudo-elements */
  border-radius: 20px;
  overflow: hidden; /* Ensure border-radius affects pseudo-elements */
}

.about-us-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/about-us/yellow_bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 91;
}

.about-us-banner::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/about-us/hindu-temple.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  z-index: 99;
}

.about-us-banner-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 70vh;
}

.about-us-banner-content h2 {
  color: #242424;
  font-family: "Objectivity";
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 50px;
  margin: 0px 0px 10px 0px;
  z-index: 999;
}

.about-us-banner-content p {
  color: #242424;
  font-family: "Objectivity";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
  width: 450px;
  z-index: 9999;
}

#ANANTA_BG {
  position: relative;
  padding: 100px 0px 0px 0px;
  overflow: hidden;
}

#ANANTA_BG .nama-bg {
  position: absolute;
  background-image: url("../images/devasmriti-digitally/nama.svg");
  width: 300px;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  bottom: 0%;
  right: 0;
  z-index: -999;
}

.about-us-content {
  margin: 80px 0px;
}

.about-us-main-content {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  padding: 0 30px;
}

.about-us-main-content h3 {
  color: #2c2c2c;
  font-family: "Objectivity";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px 0px 10px 0px;
}

.about-us-main-content p {
  color: #242424;
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin: 0px 0px 50px 0px;
  // width: 839px;
}

.about-us-main-content p:nth-child(2) {
  margin: 0px 0px 0px 0px;
}

.about-us-main-content-1 p {
  color: #242424;
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin: 0px 0px 40px 0px;
  text-align: center;
}

//

// LOGIN POP

.MuiDialog-paperScrollPaper {
  width: 100% !important;
  margin: 10px !important;
  position: relative;
  border-radius: 20px !important;
}

// .MuiDialog-paperScrollPaper::before {
//   content: "";
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   // background-image: url("../images/login-popup/log-in-popup.svg");
//   background-image: url("../images/address/Temple.svg");
//   background-repeat: no-repeat;
//   background-position: right bottom;
//   // background-size: cover;
//   bottom: -25px;
//   right: -69px;
// }

.welcome-back.welcome-otp-2 {
  position: relative;
}

.welcome-back.welcome-otp-2:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  // background-image: url("../images/login-popup/log-in-popup.svg");
  background-image: url("../images/banner/lord-shiva.svg");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: contain;
  top: -25px;
  left: -72px;
  pointer-events: none;
}

// .custom-login-modal {
//   position: relative;
//   width: 100%;
//   height: 100%;
// }

.custom-login-modal::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  // background-image: url("../images/login-popup/log-in-popup.svg");
  background-image: url("../images/banner/om-sm-right.svg");
  background-repeat: no-repeat;
  background-position: left top;
  // background-size: cover;
  top: -192px;
  left: 39px;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.01);
  pointer-events: none;
  transform: rotate(-41deg);
}

.back-home {
  padding: 20px 20px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.welcome-back {
  padding: 20px 20px;
  z-index: 999;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.welcome-back.welcome-back-2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  // background-image: url("../images/login-popup/log-in-popup.svg");
  background-image: url("../images/address/Temple.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
  // background-size: cover;
  bottom: -25px;
  right: -69px;
  pointer-events: none;
}

.welcome-back.welcome-back-2 .welcome-back-content,
.welcome-back.welcome-otp-2 .welcome-back-content {
  width: unset;
  border: unset;
  padding: unset;
}

.welcome-back-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.67px solid #ff5b00;
  border-radius: 11.24px;
  width: 50%;
  // height: 370px;
  padding: 10px 12px;
  background: white;
}

.welcome-back-content h2 {
  color: #212121;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings:
    "salt" on,
    "liga" off;
  font-family: "Objectivity";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px 0px 10px 0px;
}

.welcome-back-content p {
  color: #a1a1a1;
  text-align: center;
  font-feature-settings:
    "salt" on,
    "liga" off;
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  margin: 0;
  letter-spacing: 0.2px;
}

.welcome-back-content p span {
  color: #242424;
}

.welcome-back .mobile-number {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
}

.welcome-back .mobile-number label {
  color: #ff5b00;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings:
    "salt" on,
    "liga" off;
  font-family: "Objectivity";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 0px 0px 5px 0px;
}

.welcome-back .mobile-number .input-2 {
  border: 0.6px solid var(--Text---Secondary-Color, #676767);
  height: 35px;
  width: 200px;
  border-radius: 5px;
}

.welcome-back .mobile-number .input-1 {
  border: 0.6px solid var(--Text---Secondary-Color, #676767);
  height: 35px;
  width: 55px;
  margin-right: 05px;
  border-radius: 5px;
}

.welcome-back .mobile-number .input-1::placeholder,
.welcome-back .mobile-number .input-2::placeholder {
  font-family: "Objectivity";
  color: #7e91ae;
  font-size: 12px;
  padding-left: 30px;
}

// .mobile-number .phone-inputs {
//   display: flex;
// }

.custom-phone-number .flag-dropdown .selected-flag .arrow {
  display: none;
}

.custom-phone-number .flag-dropdown .selected-flag::after {
  content: "";
  background: url("../images/banner/down-ar.svg") no-repeat center;
  background-size: 20px 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
}

.custom-phone-number .form-control {
  padding-left: 60px !important;
}

.react-tel-input.custom-phone-number .flag-dropdown {
  background-color: unset;
  border: unset;
  border-radius: 3px 0 0 3px;
}

.react-tel-input.custom-phone-number .form-control {
  border: unset;
}

.mobile-number > .phone-inputs {
  display: flex;
  align-items: center;
  font-family: Objectivity;
}

.phone-inputs > .input-group {
  display: flex;
  align-items: center;
  border: 0.6px solid var(--Text---Secondary-Color, #676767);
  border-radius: 13px;
  padding: 8px;
  position: relative;
}

.phone-inputs > .input-group .flag-icon {
  width: 28px;
  height: 24px;
  margin-right: 10px;
}

.phone-inputs > .input-group .flag-icon:first-child {
  margin-right: unset;
}

.phone-inputs > .input-group .phone-code-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: none;
  font-size: 16px;
  color: #686868;
  // margin-right: 10px;
}

.phone-inputs > .input-group .input-number {
  border: none;
  outline: none;
  font-size: 16px;
  flex: 1;
  padding: 5px;
  color: #686868;
}

.phone-inputs > .input-group .phone-code-select:focus,
.phone-inputs > .input-group .input-number:focus {
  outline: none;
}

.phone-inputs > .input-group .vertical-line {
  width: 2px;
  height: 20px;
  background-color: #abaaaa;
  margin: 0 10px;
}

.welcome-back .mobile-number input:focus-visible {
  outline: unset;
}

.mobile-number p {
  color: #a1a1a1;
  font-feature-settings:
    "salt" on,
    "liga" off;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  // margin: 10px 0px 0px 10px;
  margin-top: 10px;
}

.send-otp {
  margin-top: 30px;
}

.send-otp-number {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.send-otp-number input {
  width: 50px !important;
  height: 50px !important;
  border-radius: 10px;
  border: 1px solid #4582bd;
}

.send-otp-number input:focus-visible {
  outline: unset !important;
}

.send-otp-number input:not(:first-child) {
  margin-left: 12px;
}

.send-otp-number span {
  font-size: 0px;
}

.resending-otp {
  margin-top: 10px;
}

.custom-back-arrow:before {
  content: "";
  position: absolute;
  width: 22px;
  height: 22px;
  // background-image: url('../images/banner/om-sm-left.svg');
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  top: 18px;
  left: 14px;
  background-image: url("../images/banner/arrow-back.svg");
  background-color: rgba(0, 0, 0, 0.02);
}

.custom-img-otp:before {
  content: "";
  position: absolute;
  width: 50%;
  height: 50%;
  // background-image: url('../images/banner/om-sm-right.svg');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
  bottom: -72px;
  right: -35px;
  pointer-events: none;
  background-image: url("../images/banner/om-sm-right.svg");
  background-color: rgba(0, 0, 0, 0.02);
  transform: rotate(-45deg);
}

.send-otp-number .resending-otp p {
  color: #a1a1a1;
  font-feature-settings:
    "salt" on,
    "liga" off;
  font-family: "Inter";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 0px 0px 10px 0px;
  text-align: center;
}

.send-otp-number .resending-otp p span {
  color: #242424;
  font-weight: 700;
  font-size: 15px !important;
}

.send-otp-number .resending-otp h5 {
  color: #ff5b00;
  font-feature-settings:
    "salt" on,
    "liga" off;
  font-family: "Inter";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 0px 10px 0px 0px;
  text-align: center;
}

.resend {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  width: 100%;
}

.receive-otp p {
  color: #a1a1a1;
  font-feature-settings:
    "salt" on,
    "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}

.resend-btn Button {
  font-family: "Objectivity" !important;
  font-style: normal;
  font-weight: 500;
  text-transform: unset !important;
}

.resend-btn span {
  color: #a1a1a1;
  font-feature-settings:
    "salt" on,
    "liga" off;
  font-size: 14px !important;
  line-height: 24px;
  text-transform: unset !important;
  margin-left: 3px;
}

.verify-otp {
  margin-top: 20px !important;
}

.verify-otp Button {
  padding: 10px 50px !important;
}

//

// NEW CHECHOUT PANEL START
.chech-box-panel-main {
  // padding: 20px 0px;
  padding: 20px;
}

.chech-box-panel-main-2 {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.add-to-card-seva .app-new-add-to-card-seva-content {
  margin-top: 20px !important;
}

.check-out-panel {
  margin: 20px 0px 0px 0px;
}

.check-out-panel .MuiPaper-rounded {
  background-color: unset;
  box-shadow: unset;
  padding: 10px 10px;
  border: 0.6px solid rgba(255, 91, 0, 0.4);
  border-radius: 10px !important;
}

.check-out-panel .add-family-content h5,
.custom-edit-profile h5 {
  color: #4f4f4f;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0px 0px 5px 0px !important;
}

.check-out-panel .add-family-content span {
  color: #747474;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.check-out-panel .MuiAccordionSummary-contentGutters {
  margin: 0px !important;
}

.check-out-panel #panel1a-content .MuiTypography-body1 {
  color: #747474;
  font-family: "Objectivity";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}

.select-delivery-address {
  margin-top: 20px;
}

.deliver-address-content {
  display: flex;
  justify-content: space-between;
}

.deliver-address-title h3 {
  color: #2c2c2c;
  font-family: "Objectivity";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin: 0;
}

.deliver-address-content .view-all Button {
  color: #ff5b00;
  leading-trim: both;
  text-edge: cap;
  font-family: "Objectivity";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: unset;
}

.deliver-address-content .view-all Button:hover {
  background-color: unset;
}

.select-address-home .address .home {
  display: flex;
  align-items: flex-start;
}

.select-address-home .address .home span {
  padding: 0px !important;
}

.select-address-home .address .home h3 {
  color: #4f4f4f;
  leading-trim: both;
  text-edge: cap;
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  margin: 0px 0px 0px 5px;
}

.select-address-home .address p {
  color: #505050;
  font-family: "Objectivity";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 10px 0px 0px 28px;
}

.select-address-home {
  margin-top: 30px;
  border: 0.6px solid rgba(255, 91, 0, 0.4);
  border-radius: 10px;
  padding: 20px 10px;
}
._7vfe {
  min-width: 450px !important;
}
// .select-address-box {
//   display: flex;
//   justify-content: space-between;
// }

.select-address-home-2 {
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.select-address-home-2 .select-address-home {
  margin-bottom: 20px;
}

.add-new-address {
  margin-top: 30px;
}

.app-new-address-2 {
  margin-top: 0px !important;
}

.app-new-address-2 Button {
  padding: 5px 8px !important;
  font-size: 13px !important;
  // border: unset !important;
  border: unset;
  background-color: unset !important;
}

.add-new-address Button {
  border-radius: 40.87px;
  border: 1.277px solid #ff5b00;
  color: #ff5b00;
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 05px 8px;
  margin-bottom: 10px;
}

.chechout-checkbox-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkout-label-details {
  display: flex;
  align-items: center;
}

.checkout-label-details-2 Button {
  color: #ff5b00;
  font-family: "Objectivity";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 5px 8px;
  margin-top: 20px;
  background-color: unset !important;
}

.checkout-label-details p {
  color: #676767;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin: 0;
}

.checkout-label-details p span {
  color: #ff5b00;
}

//

// CONFORMATION POPUP

.welcome-back-content h4 {
  color: #2c2c2c;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin: 0px 0px 10px 0px;
  font-family: Objectivity;
  letter-spacing: 0.2px;
}

.welcome-back-content h5 {
  color: #2c2c2c;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin: 0px 0px 10px 0px;
}

.welcome-back {
  position: relative;
}

.welcome-back .ganesh-leaf {
  position: absolute;
  right: -16px;
  top: 0;
}

.confirmation-btn .login-btn {
  color: #fff !important;
}

//

// SPEEDDEAL

.speeddeal .MuiBox-root .MuiSpeedDial-directionUp .MuiFab-sizeLarge {
  border: 0.6px solid rgba(255, 91, 0, 0.3) !important;
  opacity: 0.8 !important;
  background: #fff !important;
  position: relative;
}

.speeddeal .MuiBox-root .MuiSpeedDial-directionUp .MuiFab-sizeLarge svg {
  width: 0;
}

.speeddeal .MuiBox-root .MuiSpeedDial-directionUp .MuiFab-sizeLarge::before {
  content: "";
  position: absolute;
  background-image: url("../images/profile/share.svg");
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.champaign-tab .nav {
  position: sticky;
  top: 130px;
  display: flex;
  padding: 7px 0;
  z-index: 1;
  background-color: #fff;
}

.champaign-tab .nav button {
  text-transform: capitalize;
  color: #505050;
  cursor: pointer;
  padding: 10px 20px;
  transition: background-color 0.3s;
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
}

.champaign-tab .nav button.active {
  position: relative;
  color: #ff5b00;
}

.champaign-tab .nav button.active::after {
  content: "";
  background-color: red;
  width: 100%;
  position: absolute;
  height: 1.5px;
  bottom: 0px;
  left: 0px;
}

.champaign-tab .section1,
.champaign-tab .section2,
.champaign-tab .section3,
.champaign-tab .section4 {
  // position: relative;
  padding-bottom: 30px;
}

// terms and conditions
.privacy-policy {
  padding: 128px 0px 0px 0px;
}

.privacy-policy-title {
  margin: 20px 0px;
}

.privacy-policy-content h1 {
  font-size: 50px;
  line-height: 60px;
  font-weight: 800;
  color: #000;
  margin: 0px 0px 0px 0px;
  text-align: center;
}

.privacy-policy-title h4 {
  color: #000;
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  margin: 0px 0px 10px 0px;
}

.privacy-policy-title p {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin: 0px 0px 15px 0px;
}
.privacy-policy-title p a {
  color: #ff5b00;
}

.privacy-policy-content {
  background-color: #fee6e1;
  padding: 120px 0px;
}

// .privacy-policy-title p:not(:nth-child(3)) {
//     margin: 0px 0px 15px 0px;
// }

// .privacy-policy-title p:not(:nth-child(2)) {
//     margin: 0px 0px 0px 0px;
// }

.intro-content {
  margin: 30px 0px;
}

.intro-content h5 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  margin: 0px 0px 10px 0px;
  color: #000;
}

.intro-content p {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.intro-content ul {
  margin: 0;
}

.intro-content ul li {
  margin: 0px 0px 10px 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000;
}

@media (min-width: 320px) and (max-width: 767px) {
  .description-content iframe,
  .description-content img {
    width: 100% !important;
    height: auto;
  }
  #AppBanner {
    height: unset;
  }

  .banner-content .app-btn img {
    display: none;
  }

  .banner-content {
    padding: 122px 0px 0px 0px;
  }

  .banner-content h1 {
    font-size: 26px;
    line-height: 40px;
    margin-bottom: 10px;
    width: auto;
    // text-align: left;
  }

  .banner-content p {
    width: auto;
    font-size: 12px;
    line-height: 22px;
    text-align: justify;
  }

  #AppBanner .cloud-left img,
  #AppBanner .cloud1-left img,
  #AppBanner .cloud-right img,
  f #AppBanner .cloud1-right img {
    width: 0;
  }

  .app-btn {
    padding: 10px 20px !important;
    margin-top: 40px !important;
  }

  .banner-image .banner-mb {
    display: block;
  }

  .banner-image .banner-lp {
    display: none;
  }

  .discover-more {
    margin-top: 80px;
  }

  .discover-more-buttons h2 {
    font-size: 20px;
  }

  .discover-more-buttons Button {
    font-size: 16px !important;
  }

  .app-btn-img {
    width: 17px;
    height: 17px;
  }

  .nava-chandi-bg p {
    font-size: 12px;
    line-height: 20px;
  }

  .nava-chandi-bg p span {
    // font-size: 12px;
    font-size: 14px;
    line-height: 20px;
  }

  .durgamata {
    margin-top: -10px;
  }

  .book-seva {
    margin-top: -20px;
  }

  .durgamata .date-time p {
    font-size: 12px;
    white-space: nowrap;
  }

  .book-seva .book-seva-btn {
    padding: 10px 30px !important;
  }

  .our-special-offers {
    margin: 20px 0px;
  }

  .our-special-offers-box {
    background-image: unset;
    background-color: #ffff;
    background-size: cover;
    padding: 20px;
  }

  .offers-button {
    margin-top: -20px;
  }

  .our-special-offers-content h2 {
    font-size: 24px;
    line-height: 34px;
  }

  .our-special-offers-content p {
    font-size: 12px;
    line-height: 20px;
  }

  .featured-seva-box h4 {
    font-size: 25px;
    line-height: 30px;
    text-align: center;
  }

  .fearuted-box-content {
    margin-top: 40px;
  }

  .box-content {
    box-shadow:
      0 1px 2px rgba(0, 0, 0, 0.07),
      0 2px 4px rgba(0, 0, 0, 0.07),
      0 4px 8px rgba(0, 0, 0, 0.07),
      0 8px 16px rgba(0, 0, 0, 0.07),
      0 16px 32px rgba(0, 0, 0, 0.07),
      0 32px 64px rgba(0, 0, 0, 0.07);
    padding: 10px 0px;
    border-radius: 20px;
    margin-bottom: 10px;
  }

  .box-content h4 {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  .featured-seva-box p {
    font-size: 0px;
  }

  .featured-sevas {
    margin: 50px 0px 0px 0px;
    padding: 0px;
  }

  .featured-sevas::after {
    width: 0px;
  }

  .box-content img {
    width: 100px;
  }

  .devasmriti-digital-main {
    margin-top: 40px;
  }

  .devasmriti-digital-content h2 {
    font-size: 24px;
    line-height: 34px;
    width: auto;
  }

  .devasmriti-digital {
    margin-top: 60px !important;
  }

  .devasmriti-digital-seva-content::before {
    height: 0;
  }

  .devasmriti-digital-seva-content {
    margin: -10px 0px 0px 0px;
  }

  .devasmriti-digital-seva-content h2 {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 5px;
  }

  .devasmriti-digital-seva-content-2 h2,
  .devasmriti-digital-seva-content-3 h2 {
    color: #ff5b00;
  }

  .devasmriti-digital-seva-content h5 {
    margin-bottom: 5px;
  }

  .devasmriti-digital-seva-content h5 span {
    font-size: 20px;
    line-height: 30px;
  }

  .devasmriti-digital-main .nama-bg {
    width: 0;
  }

  .happy-customers {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .happy-customers-content h3 {
    font-size: 16px;
  }

  .happy-customers-content h2 {
    font-size: 24px;
    line-height: 34px;
  }

  .happy-customers-content p {
    font-size: 15px;
    line-height: 25px;
  }

  .happy-customers-card-content p {
    font-size: 14px;
    line-height: 24px;
  }

  .happy-customers-sliders {
    margin-top: 30px;
  }

  .faq {
    margin: 25px 0px 50px 0px;
  }

  .faq-content h2 {
    font-size: 30px;
    line-height: 40px;
  }

  .faq-main-content {
    margin-top: 30px;
  }

  .accordion .main-content {
    font-size: 14px;
  }

  .accordion .mini-content {
    font-size: 13px;
    line-height: 23px;
  }

  .footer-logo-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .footer-logo-content img {
    width: 160px;
  }

  .footer-logo-content p {
    width: auto;
    text-align: center;
    font-size: 15px;
  }

  .get-to-know {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .get-to-know ul {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .get-to-know-2 ul li {
    text-align: center;
  }

  .footer {
    padding: 10px 0px;
  }

  .bookseva-lorem {
    background-color: #fff;
    padding: 40px 0px;
  }

  .bookseva-lorem-content h2 {
    font-size: 24px;
    line-height: 34px;
    margin: 0;
  }

  // EXPLORE START
  .categories {
    // margin-top: 60px;
    margin-top: 80px;
  }

  .categories-content h2 {
    font-size: 22px;
    line-height: 32px;
  }

  .categories-gallery {
    overflow-x: scroll;
  }

  .categories-gallery Button {
    min-width: unset;
  }

  .categories-gallery Button:not(:first-child) {
    margin-left: 20px;
  }

  .categories-tabs {
    margin-top: 30px;
    margin-bottom: 0px;
  }

  #EXPLORE_BG .nama-bg {
    width: 0;
  }

  // EXPLORE END

  // CART START
  .app-new-add-to-cart {
    padding: 100px 0px 60px 0px;
  }

  .cart-image img {
    width: 80px;
  }

  .add-to-cart-box-content {
    display: flex;
    flex-direction: column;
  }

  .add-to-cart-box:not(:last-child) .add-to-cart-box-content {
    padding-bottom: 13px;
    border-bottom: 1px solid #e8e8e8;
  }

  .add-to-cart-box:not(:last-child) {
    padding-bottom: unset;
  }

  .app-new-sm-cart-box {
    margin-left: 10px;
  }
  .app-new-sm-cart-box h4 {
    white-space: unset !important;
  }
  .add-to-cart-box-main-content {
    display: flex;
    position: relative;
    // height: 130px;
  }
  .add-to-cart-box-mini-content {
    margin-left: 0px;
    margin-top: unset;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .add-cart-price-remove-btn {
    display: flex;
    flex-direction: unset;
    margin-top: 20px;
  }

  .add-to-cart-box-mini-content h4 {
    white-space: nowrap;
    margin-bottom: 10px;
  }

  .add-to-cart-box-mini-content h5 {
    margin-bottom: 10px;
  }

  .preference-form {
    margin-top: 10px;
  }

  // vishal
  .add-to-cart-box:not(:first-child) {
    margin-top: unset;
  }

  .add-to-cart-box {
    border-radius: unset;
  }

  .add-to-cart-content .add-to-cart-box:first-child {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .add-to-cart-content .add-to-cart-box:last-child {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .add-to-cart-box {
    box-shadow: none;
  }

  .add-to-cart-content .add-to-cart-child-content {
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .add-to-cart-box11 {
    padding: 20px;
    background: none;
  }

  // .add-to-cart-box-main-content .cart-image {
  //   position: relative;
  // }

  .add-to-cart-box-main-content .cart-image .delete_dustbin {
    content: "";
    position: absolute;
    top: -7px;
    // right: 4px;
    right: -9px;
    width: 33px;
    height: 33px;
    background-image: url("../images/cart/dustbin.svg");
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
  }

  .order-summary,
  .add-to-cart-title h2 {
    margin: 0 20px;
  }

  .add-to-cart-title h2 {
    font-size: 21px;
  }

  // INDIVIDUAL START
  // .choose-seva-content .MuiInputBase-formControl {
  //   width: 200px !important;
  // }

  .choose-seva-content {
    width: auto;
  }

  .individual-content h2 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 14px;
  }

  .choose-seva-content .choose-seva-select {
    margin-top: 10px !important;
  }

  .choose-seva-cost h2 {
    font-size: 22px;
  }

  .choose-seva-booking-cost {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .choose-seva-cost {
    margin-top: 14px;
  }

  .choose-seva-booking-cost .bookseva {
    // margin: 10px 0px 0px 0px;
    width: 100%;
  }

  .app-new-individual-suggested-puja {
    margin-bottom: 40px;
  }

  .app-new-individual-suggested-puja {
    margin-top: 0px !important;
  }

  .MuiTabs-flexContainer {
    overflow-x: scroll;
  }

  .description-tabs {
    display: none;
  }

  .description-tabs-2 {
    display: block;
    margin-top: 40px;
  }

  .descripition-tabs-2-content .description-content {
    margin-top: 10px;
  }

  .faq-2 {
    // margin-top: 20px;
    margin-bottom: 20px !important;
  }

  .faq-main-content-2 {
    margin-top: unset;
  }

  .faq-2 .faq-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .faq-2 .faq-main-content {
    margin: 0px;
  }

  .faq .faq-content h2 {
    color: #24292e;
    font-size: 24px;
    line-height: 26px;
    margin: 20px 0px;
  }

  .thila-homam {
    // padding: 160px 0px 60px 0px;
    padding: 150px 0px 15px 0px;
  }

  .individual-content h5 {
    font-size: 16px;
    font-weight: 500;
  }

  #AppBanner2 {
    background-position: center;
  }

  // INDIVIDUAL END

  // CHAMPAIGN START
  .ananta-padmanbu-mini-content {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: flex-start;
  }

  .ananta-mini-content h4 {
    margin-bottom: 20px;
  }

  .ananta-padmanbu-btn Button {
    margin: 0 !important;
    // width: 240px;
    border-radius: 15px;
  }

  .ananta-padmanbu-btn {
    margin: 0 auto;
  }

  .champaign-tab {
    margin-bottom: 30px;
  }

  // CHAMPAIGN END

  // ABOUT US START

  .about-us-banner {
    margin-top: unset;
    background-position: right;
    height: unset;
    border-radius: unset;
  }

  .about-us-banner-content {
    height: 40vh;
  }

  .about-us-banner-content h2 {
    font-size: 21px;
    line-height: 25px;
    margin-bottom: 5px;
  }

  .about-us-banner-content p {
    font-size: 11px;
    width: 60%;
    line-height: 20px;
  }

  .about-us-banner::after {
    top: unset;
    bottom: 0;
    width: 80%;
    height: 80%;
  }

  .about-us-content {
    margin: 40px 0px;
  }

  .about-us-main-content h3 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 5px;
  }

  .about-us-main-content p {
    font-size: 12px;
    line-height: 22px;
    width: auto;
  }

  // #ANANTA_BG .nama-bg {
  //   width: 0;
  // }

  .about_us_recognise h2 {
    font-size: 20px;
    padding: 0 13px;
  }

  .about_us_recognise h2::before,
  .about_us_recognise h2::after {
    width: 65px;
  }

  .footer .om_footer::after {
    bottom: 80px;
    right: 10px;
  }

  // ABOUT US END

  // PROFILE START
  .profile {
    margin: 0px 0px 40px 0px;
  }

  .profile-container {
    padding: 10px;
  }

  .profile-content {
    display: flex;
    flex-direction: column;
  }

  .profile-menu {
    width: 100%;
    height: unset;
    margin-bottom: 40px;
  }

  .profile-information-box {
    width: 100%;
    box-shadow: 0px 4px 15px 0px #0000000d;
    border-radius: 8px;
  }

  .profile-information {
    width: 100%;
    margin-top: 10px;
    padding: 0;
  }

  .profile-information h2 {
    font-size: 18px;
    font-weight: 700;
    margin-left: 10px;
    padding: 0px !important;
  }

  .my-profile-info {
    margin-left: 0px;
  }

  .profile-log-out-btn {
    display: none;
  }

  .accordion_profile_mob {
    .accordion_box {
      background: #fef9f3 !important;
      padding: 14px 0px 14px 14px;
      margin: 12px 0 !important;
      border-color: #fef9f3;
      border-radius: 10px;

      p {
        color: #0f0f0f !important;
      }
    }
  }

  // FAMILY START
  .personal-information-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .personal-information-content h2 {
    margin: 10px 0px 0px 0px;
    font-size: 16px;
    font-weight: 500;
  }

  .personal-information-content img {
    display: block;
    width: 45px;
  }

  .personal-information.addr-flex-disable .personal-information-content h2 {
    padding-right: 15px !important;
  }

  .relation-content {
    margin: 10px 0px 0px 0px;
  }

  .addmore-btn-content {
    margin: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: auto;
  }

  // .myfamily-forms-btn {
  //   display: flex;
  //   flex-direction: column;
  // }

  // .myfamily-forms-btn button:nth-child(2) {
  //   margin: 10px 0px 0px 0px;
  // }

  // CHECK OUT

  .add-to-cart-box-2 img {
    width: 50px;
    height: 50px;
  }

  .add-to-cart-box-2 {
    display: flex;
    margin-top: 30px;
  }

  .add-to-card-seva-content {
    margin-left: 0px;
  }

  .add-to-card-seva {
    display: flex;
    margin-left: 10px;
  }

  .add-to-card-seva-content p {
    margin-top: 20px;
  }

  .add-to-card-seva-content-2 {
    display: flex;
    flex-direction: column;
  }

  .add-to-card-seva-content-2 h4 {
    font-size: 14px;
    line-height: 24px;
  }

  .add-to-card-seva-content-2 p:nth-child(2) {
    margin: 0px 0px 0px 10px;
  }

  .add-family-details-btn button {
    padding: 10px !important;
    font-size: 15px;
  }

  .top-stepper .MuiStepper-horizontal {
    overflow: scroll;
  }

  .delivery-options-content-box {
    display: flex;
    flex-direction: column;
    width: auto;
  }

  .delivery-options-content-box-1 {
    width: auto;
    margin-bottom: 10px;
  }

  .champaign-btns Button {
    width: 100%;
  }
  // ADDRESS
  .search-order .search-btn {
    padding: 15px 20px;
    font-size: 12px;
  }

  .search-bar input {
    font-size: 12px;
    line-height: 22px;
  }

  .search-bar input::placeholder {
    font-size: 12px;
    line-height: 22px;
  }

  .search-box-title {
    margin-top: 30px;
  }

  .search-box {
    margin: 10px 0px;
  }

  .search-box-title h3 {
    font-size: 18px;
    list-style: 28px;
  }

  .search-box-title h3 span {
    font-size: 14px;
    line-height: 24px;
  }

  .search-box-mini .deliver-date h3 {
    font-size: 12px;
    line-height: 22px;
  }

  .search-box-mini-content img {
    width: 80px;
  }

  .search-box-mini .deliver-date h4 {
    font-size: 15px;
    line-height: 25px;
  }

  .search-box-mini .deliver-date p {
    font-size: 13px;
    line-height: 23px;
  }

  .search-box-main .arrow img {
    width: 0px;
  }

  .search-box-mini .deliver-date {
    margin-top: 0px;
  }

  .add-to-cart-box-main-content .cart-image {
    width: 140px;
  }

  // CHECK OUT PANEL

  .deliver-address-title h3 {
    font-size: 18px;
    line-height: 26px;
  }

  .check-out-panel {
    margin: 0px;
  }

  .add-to-cart-box h5 {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 8px;
  }

  .select-address-home .address p {
    margin-bottom: 5px;
  }

  .add-new-address Button {
    padding: 10px 25px;
    font-size: 15px;
  }

  // .add-new-address Button {
  //   border-radius: 8px;
  // }

  .add-to-card-seva-content h4 {
    font-size: 16px;
    line-height: 24px;
  }

  .checkout-label-details p {
    font-size: 13px;
    line-height: 23px;
  }

  // SPEED DEAL

  .individual-image .speeddeal {
    left: 28%;
  }

  //
  .add-to-cart-22,
  .add-to-cart-2 {
    padding: 15px 0px 60px 0px;
  }

  //   UPDATE PAGE

  .update-main-content {
    display: flex;
    flex-direction: column;
  }

  .update-main-box {
    margin: 20px 0px 0px 0px !important;
  }

  .update-title {
    display: flex;
  }

  .upadate-main-content {
    margin-top: 10px !important;
  }

  .upadate-main-content p {
    margin-bottom: 20px !important;
  }

  .about-us-main-content {
    width: auto;
  }
  // PRIVACY POLICY

  .privacy-policy-content {
    padding: 60px 0px;
  }
  .privacy-policy-content h1 {
    font-size: 30px;
    line-height: 40px;
  }
  //
  ._3gpv > img {
    height: 250px;
    background-color: #e7e7e7;
  }
  .ananta-padmanabu {
    margin-top: 20px;
  }
  .durgamata .strip {
    position: absolute;
    bottom: 10px;
    left: 49%;
    transform: translate(-50%, -50%);
  }
  .durgamata .date-time {
    position: absolute;
    bottom: -8%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  //vishal

  .multiple-states {
    padding: 15px 0;
  }
  .multiple-states h2 {
    padding: 20px 0;
    font-size: 24px;
    line-height: 34px;
  }

  .india-map {
    height: 56vh;
  }

  .our-brands-content h2 {
    font-size: 23px;
    line-height: 26px;
    margin: 20px 0px;
  }

  .our-brands-content h2::before,
  .our-brands-content h2::after {
    width: 40%;
  }

  // .mobile-hide {
  //   display: none;
  // }

  .devasmriti-digital-seva-content h5 {
    font-size: 1.2rem;
  }

  .box-content {
    width: auto;
  }

  .nama-bg {
    display: none;
  }

  #ANANTA_BG .about-us-content:first-child {
    position: relative;
  }

  #ANANTA_BG .about-us-content:first-child::before {
    content: "";
    position: absolute;
    background-image: url("../images/devasmriti-digitally/nama.svg");
    width: 260px;
    height: 260px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    top: -32px;
    left: -156px;
    z-index: -999;
  }

  #ANANTA_BG .about-us-content:first-child::after {
    content: "";
    position: absolute;
    background-image: url("../images/devasmriti-digitally/nama.svg");
    width: 280px;
    height: 280px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    top: 50px;
    right: -94px;
    z-index: -999;
  }

  .top-stepper {
    margin: 15px 0px 35px 0px;
  }

  // payment successul modal
  .welcome-back-content {
    width: 92%;
    // box-shadow: 0px 4px 15px 0px #0000000D;
  }

  .myfamily-forms-1 .MuiOutlinedInput-notchedOutline,
  .myfamily-forms-2 .MuiOutlinedInput-notchedOutline {
    border-radius: 8px;
  }

  .myfamily-forms-1 .MuiFormLabel-root,
  .myfamily-forms-2 .MuiFormLabel-root {
    // font-size: 13px;
    line-height: 20px;
  }

  .myfamily-forms-btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  // .myfamily-forms-btn button {
  //   flex: 1 1 calc(33.33% - 16px);
  //   max-width: calc(33.33% - 16px);
  // }

  .myfamily-forms-btn button:first-child,
  .myfamily-forms-btn button:nth-child(2) {
    border-radius: 8px;
    font-size: 14px;
    padding: 13px 16px;
  }

  .myfamily-forms-btn button:nth-child(2) {
    margin-left: 20px;
  }

  .addmore-btn-content Button {
    border-radius: 8px;
    padding: 5px 14px;
    margin-top: 10px;
  }

  .edit-btn.edit-mob Button {
    border: unset;
    border-radius: unset;
    padding: unset;
    margin-left: unset;
  }

  .edit-btn.edit-mob .cancel::after {
    right: -7px;
    top: -2px;
  }

  .edit-btn.edit-mob Button::after {
    right: 0px;
    top: -2px;
  }

  .relation-content .relation h5 {
    font-size: 14px;
    margin: 0px 0px 6px 0px;
  }

  .profile-information-box .relation-content .relation p {
    font-size: 12px;
    word-break: break-word;
    word-wrap: break-word;
    white-space: normal;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  .addmore-btn-content p {
    font-size: 14px;
  }

  .personal-information {
    margin-bottom: 22px;
    align-items: flex-start;
  }

  .addr-flex-disable {
    align-items: center;
  }

  .add-h-line::after {
    width: 50%;
  }

  .personal-information-form.cust_profile_form .form-group input {
    border-color: #676767;
    border-radius: 8px;
    background-color: white;
  }

  .personal-information-form.cust_profile_form .form-group label {
    color: #7e91ae;
  }

  // To do: working on it

  // .all-seva-accordion .cart-image {
  //   position: relative;
  // }

  .all-seva-accordion .cart-image .delete_dustbin {
    content: "";
    position: absolute;
    top: -10px;
    right: -18px;
    // left: -18px;
    width: 35px;
    height: 35px;
    background-image: url("../images/cart/dustbin.svg");
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
  }

  .cust-invoice-image {
    width: 135px;
    height: 60px;
  }

  .custom-swiper-button-prev svg,
  .custom-swiper-button-next svg {
    font-size: 38px !important;
  }

  .happy-customers .cust-sliding-arrow {
    margin-top: 20px !important;
  }

  .discover-more-boxs {
    margin: 20px 0px;
  }

  // .happy-customers-card-content > img {
  //   width: 23px;
  // }

  .our-brands {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .banner-content button {
    padding: 6px 15px !important;
  }

  .categories-tabs-content .swiper-horizontal {
    padding-bottom: 20px;
  }

  .bookseva-lorem-content button .app-btn-img {
    width: 14px;
    height: 14px;
    margin-left: 2px;
  }

  .bookseva-lorem-content button.app-btn {
    padding: 7px 11px !important;
  }

  .welcome-back.welcome-back-2::before {
    width: 51%;
    height: 80%;
    bottom: -54px;
  }

  .send-otp.verify-otp .app-btn {
    margin-top: unset !important;
  }

  .send-otp-number input:not(:first-child) {
    margin-left: 6px;
  }

  .custom-champaign-parent-box {
    flex-direction: column;
    gap: 16px;
    height: unset;
  }

  ._3gpv {
    width: 100%;
    border-radius: unset;
  }

  .ananta-padmanabu {
    // width: 92%;
    margin: 0 auto;
    width: 90%;
  }

  .custom-champaign-parent-box {
    margin: unset;
    margin-top: 82px;
  }

  ._3gpv > img {
    border-radius: unset;
    // object-fit: contain;
  }

  .individual-image img {
    border-radius: unset;
  }

  .individual-image {
    height: unset;
  }

  .individual-image .individual-image-small {
    width: 40px;
    height: 40px;
    bottom: 30px;
    left: 22px;
  }

  .individual-image .individual-image-small:before {
    width: 20px;
    height: 20px;
    bottom: 12px;
    left: 8px;
  }

  .individual-image:before {
    height: 99%;
    border-radius: unset;
  }

  .faq-main-content-2 h3 {
    margin: 0px 0px 10px 0px;
  }

  .champaign-tab .section1,
  .champaign-tab .section2,
  .champaign-tab .section3,
  .champaign-tab .section4 {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .pencil-icon {
    cursor: pointer;
    fill: none;
  }

  .pencil-icon-default {
    stroke: #ff5b00;
  }

  .pencil-icon-selected {
    stroke: #676767;
    filter: grayscale(100%);
  }

  .no-items-in-cart {
    margin: 0 20px;
  }

  .invoice-box .invoice-box-child:before {
    display: none;
  }

  .thila-homam > h3 {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 400px) {
  .coupons-main-btns .coupon-name {
    margin: 0px 0px 20px 0px;
    width: 100%;
  }

  .coupons-main-btns {
    margin-top: 30px;
  }

  .coupons-main-btns Button {
    margin: 0px;
    width: 100%;
  }

  .categories-tabs-content .durgamata img {
    width: 100%;
  }

  .categories-tabs-content .durgamata .date-time p {
    font-size: 9px;
  }

  .deliver-address-title h3 {
    font-size: 15px;
    line-height: 26px;
  }

  .deliver-address-content .view-all Button {
    font-size: 12px;
  }

  .deliver-address-content .view-all .app-btn-img {
    margin-left: 2px;
    width: 12px;
    height: 12px;
  }

  .cust-invoice-image {
    width: 113px;
    height: 50px;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .add-to-card-seva-content-2 .app-new-remove-btn {
    margin-top: 20px;
  }
  .deliver-address-content .view-all Button {
    font-size: 13px;
  }
  .deliver-address-content .view-all .app-btn-img {
    width: 14px;
    height: 14px;
    margin-left: 2px;
  }

  .deliver-address-title h3 {
    font-size: 16px;
    line-height: 26px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .banner-content h1 {
    font-size: 30px;
    line-height: 40px;
    width: auto;
  }

  .banner-content p {
    width: auto;
  }

  #AppBanner .cloud-left img,
  #AppBanner .cloud1-left img,
  #AppBanner .cloud-right img,
  #AppBanner .cloud1-right img {
    width: 0;
  }

  .app-btn {
    padding: 10px 20px !important;
  }

  .discover-more {
    margin-top: 40px;
  }

  .discover-more-buttons h2 {
    font-size: 20px;
  }

  .discover-more-buttons a {
    font-size: 17px;
  }

  .app-btn-img {
    width: 17px;
    height: 17px;
  }

  .durgamata {
    margin-top: 30px;
  }

  // .durgamata img{
  //     width: 100%;
  // }
  .book-seva {
    margin-top: 20px;
  }

  .book-seva .book-seva-btn {
    padding: 10px 30px !important;
  }

  .our-special-offers {
    margin-top: 30px;
    margin-bottom: 60px;
  }

  .our-special-offers-box {
    background-image: unset;
    background-color: #ffff;
    background-size: cover;
    padding: 20px;
  }

  .our-special-offers-content h2 {
    font-size: 30px;
    line-height: 40px;
  }

  .featured-seva-box h4 {
    text-align: center;
    font-size: 30px;
    line-height: 40px;
  }

  .featured-sevas::after {
    width: 0px;
  }

  .devasmriti-digital-main {
    margin-top: 40px;
  }

  .devasmriti-digital-content h2 {
    font-size: 30px;
    width: auto;
  }

  .devasmriti-digital {
    margin-top: 60px !important;
  }

  .devasmriti-digital-seva-content::before {
    height: 0;
  }

  .devasmriti-digital-seva-content {
    margin: 20px 0px 0px 0px;
  }

  .devasmriti-digital-seva-content h2 {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 5px;
  }

  .devasmriti-digital-seva-content-2 h2,
  .devasmriti-digital-seva-content-3 h2 {
    color: #ff5b00;
  }

  .devasmriti-digital-seva-content h5 {
    margin-bottom: 5px;
  }

  .devasmriti-digital-main .nama-bg {
    width: 0;
  }

  .happy-customers {
    margin-top: 40px;
  }

  .happy-customers-content h3 {
    font-size: 20px;
  }

  .happy-customers-content h2 {
    font-size: 30px;
    line-height: 40px;
  }

  .happy-customers-content p {
    font-size: 15px;
    line-height: 25px;
  }

  .happy-customers-sliders {
    margin-top: 30px;
  }

  .faq {
    margin: 60px 0px 30px 0px;
  }

  .faq-content h2 {
    font-size: 30px;
    line-height: 40px;
  }

  .faq-main-content {
    margin-top: 30px;
  }

  .accordion .main-content {
    font-size: 17px;
  }

  .accordion .mini-content {
    font-size: 15px;
  }

  .footer {
    padding: 20px 0px;
  }

  .footer-logo-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .footer-logo-content img {
    width: 160px;
  }

  .footer-logo-content p {
    width: auto;
    text-align: left;
    font-size: 15px;
  }

  .get-to-know {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .get-to-know ul {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .get-to-know-2 ul li {
    text-align: center;
  }

  .bookseva-lorem {
    background-color: #fff;
    padding: 80px 0px;
  }

  .bookseva-lorem-content h2 {
    font-size: 24px;
    line-height: 34px;
    margin: 0;
  }

  .bookseva-lorem-content Button {
    margin-top: 20px !important;
  }

  // EXPLORE START
  .categories {
    margin-top: 90px;
  }

  .categories-content h2 {
    font-size: 22px;
    line-height: 32px;
  }

  .categories-gallery {
    overflow-x: scroll;
    scrollbar-width: none;
  }

  .categories-gallery::-webkit-scrollbar {
    display: none;
  }

  .categories-gallery Button {
    min-width: unset;
  }

  .categories-gallery Button:not(:first-child) {
    margin-left: 20px;
  }

  .categories-tabs {
    margin-top: 30px;
    margin-bottom: 0px;
  }

  #EXPLORE_BG .nama-bg {
    width: 0;
  }

  // INDIVIDUAL START
  .thila-homam {
    padding: 200px 0px 60px 0px;
  }

  .app-new-individual-suggested-puja {
    margin-bottom: 0px;
  }

  .app-new-individual-suggested-puja {
    margin-top: 0px !important;
  }

  .champaign-tab {
    margin-bottom: 30px;
  }

  //

  // ABOUT US START
  .about-us-banner {
    background-position: right;
    height: unset;
    margin-top: 160px;
  }

  .about-us-banner-content h2 {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 10px;
  }

  .about-us-banner::after {
    bottom: 0;
    top: unset;
    right: 0;
    width: 70%;
    height: 70%;
  }

  .about-us-banner-content p {
    font-size: 26px;
    line-height: 34px;
    // width: auto;
  }

  .about-us-content {
    margin: 40px 0px;
  }

  .about-us-main-content h3 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 5px;
  }

  .about-us-main-content p {
    font-size: 15px;
    line-height: 25px;
    width: auto;
  }

  .about_us_recognise h2::before,
  .about_us_recognise h2::after {
    width: 200px;
  }

  // PROFILE START
  .profile-container {
    padding: 10px;
  }

  .profile-content {
    display: flex;
    flex-direction: column;
  }

  .profile-menu {
    width: 100%;
    height: unset;
    margin-bottom: 40px;
  }

  .profile-information-box {
    width: 100%;
  }

  .profile-information {
    width: 100%;
    margin-top: 10px;
    padding: 0;
  }

  .profile-information h2 {
    font-size: 20px;
    font-weight: 600;
    margin-left: 10px;
  }

  .profile-log-out-btn {
    bottom: 0%;
    left: 50%;
    transform: translate(-103%, -10%);
  }

  .profile-log-out-btn {
    display: none;
  }

  //

  // FAMILY START
  .personal-information-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .personal-information-content h2 {
    margin: 10px 0px 0px 0px;
  }

  .relation-content {
    margin: 10px 0px 0px 0px;
  }

  .addmore-btn-content {
    margin: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: auto;
  }

  .myfamily-forms-btn {
    display: flex;
    flex-direction: column;
  }

  .myfamily-forms-btn button:nth-child(2) {
    margin: 10px 0px 0px 0px;
  }

  //
  .checkout-label-details p {
    font-size: 14px;
    line-height: 24px;
  }

  //
  .add-to-cart-22 {
    padding: 120px 0px 60px 0px;
  }

  .featured-sevas {
    margin: 0px;
  }
  //
  .privacy-policy-content {
    padding: 60px 0px;
  }
  .privacy-policy-content h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .india-map {
    height: 75vh;
  }
  .our-brands-content h2 {
    font-size: 30px;
    line-height: 40px;
  }

  .footer .om_footer::after {
    bottom: 10px;
    right: 10px;
  }

  .profile-information-box .relation-content .relation p {
    word-break: break-word;
    word-wrap: break-word;
    white-space: normal;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  .personal-information.addr-flex-disable
    .personal-information-content
    h2::after {
    width: 42vw;
  }

  .add-h-line::after {
    width: 50%;
  }

  .profile-menu::after {
    display: none;
  }

  .order-summary,
  .add-to-cart-title h2 {
    margin: 0 20px;
  }

  .add-to-cart-box.add-to-cart-box11 {
    margin: 0 20px;
  }

  .cust-invoice-image {
    width: 210px;
    height: 80px;
  }

  .custom-champaign-parent-box {
    margin: 0 21px !important;
    margin-top: 160px !important;
    gap: 22px;
  }

  .custom-champaign-parent-box {
    height: 351px;
  }

  .custom-champaign-parent-box {
    max-width: unset;
  }

  .individual-image {
    height: 440px;
  }

  .ananta-padmanbu-content h2 {
    font-size: 19px;
    margin-bottom: 14px;
  }

  .choose-seva-cost {
    margin-top: 12px;
  }

  .individual-content h2 {
    font-size: 22px;
  }

  .order-summary button._0plu1 {
    padding: 6px 15px !important;
  }
}

@media (min-width: 768px) and (max-width: 820px) {
  .choose-seva-cost {
    margin-top: 15px;
  }

  .individual-image {
    height: 481px;
  }
}

@media (min-width: 820px) and (max-width: 854px) {
  .choose-seva-cost {
    margin-top: 28px;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  .our-special-offers-box {
    background-image: unset;
    background-color: #ffff;
  }

  // EXPLORE START

  .categories-gallery {
    overflow-x: scroll;
  }

  .categories-gallery Button:not(:first-child) {
    margin-left: 10px;
  }

  .coupons-main-btns .coupon-name {
    margin: 0px 0px 20px 0px;
    width: 100%;
  }

  .coupons-main-btns {
    margin-top: 30px;
  }

  .coupons-main-btns Button {
    margin: 0px;
    width: 100%;
  }

  // INDIVIDUAL
  .thila-homam {
    padding: 200px 0px 60px 0px;
  }

  .individual-content h2 {
    margin-bottom: 10px;
    font-size: 20px;
  }

  .individual-content h5 {
    font-size: 16px;
  }

  .individual-content h6 span,
  .individual-content h6 {
    font-size: 15px;
  }

  .individual-content h6 span {
    width: 60px;
  }

  .choose-seva-cost h2 {
    font-size: 20px;
  }

  .choose-seva-booking-cost {
    margin-top: 10px;
  }

  .categories-gallery-content span {
    font-size: 14px;
    margin-left: 5px;
  }

  // PROFILE

  .profile-container {
    padding: 0px 0px 0px 10px;
  }

  .profile-information-box {
    width: 100%;
  }

  .profile-menu {
    width: 30%;
  }

  .addmore-btn-content {
    width: auto;
  }

  //
  .footer-logo-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer-logo-content p {
    width: auto;
  }

  .nava-chandi-bg h4 {
    font-size: 16px;
  }

  .about_us_recognise h2::before,
  .about_us_recognise h2::after {
    width: 300px;
  }

  .personal-information.addr-flex-disable
    .personal-information-content
    h2::after {
    width: 24vw;
  }

  .custom-champaign-parent-box {
    height: 335px;
    max-width: 970px;
  }
}

@media (min-width: 1200px) and (max-width: 1281px) {
  .custom-champaign-parent-box {
    // height: 300px;
    max-width: 1153px;
  }
}

.champaign-tab .nav {
  position: sticky;
  top: 86px;
  // top: 130px;
  display: flex;
  padding: 7px 0;
  z-index: 1;
  // background-color: #fff;
}

.champaign-tab .nav button {
  text-transform: capitalize;
  color: #505050;
  cursor: pointer;
  padding: 10px 20px;
  transition: background-color 0.3s;
  font-family: "Objectivity";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
}

.champaign-tab .nav button.active {
  position: relative;
  color: #ff5b00;
}

.champaign-tab .nav button.active::after {
  content: "";
  background-color: red;
  width: 100%;
  position: absolute;
  height: 1.5px;
  bottom: 0px;
  left: 0px;
}

.champaign-tab .section1,
.champaign-tab .section2,
.champaign-tab .section3,
.champaign-tab .section4 {
  // position: relative;
  padding-bottom: 30px;
  padding-top: 30px;
}

//   UPADTE PAGE

.update-main-content {
  display: flex;
}

.update-main-box {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}

.update-title {
  display: flex;
}

.upadte-mini-title {
  margin-left: 10px;
}

.upadte-mini-title h5 {
  color: #222;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  margin: 0px 0px 2px 0px;
}

.upadte-mini-title p {
  color: #6e7177;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
}

.upadate-main-content {
  margin-top: 30px;
}

.upadate-main-content h3 {
  color: #222;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  margin: 0px 0px 10px 0px;
}

.upadate-main-content p {
  color: rgba(34, 34, 34, 0.7);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin: 0px 0px 30px 0px;
}

@media (min-width: 320px) and (max-width: 992px) {
  .discover-more-content .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    display: block !important;
  }

  .box-content {
    box-shadow:
      0 1px 2px rgba(0, 0, 0, 0.07),
      0 2px 4px rgba(0, 0, 0, 0.07),
      0 4px 8px rgba(0, 0, 0, 0.07),
      0 8px 16px rgba(0, 0, 0, 0.07),
      0 16px 32px rgba(0, 0, 0, 0.07),
      0 32px 64px rgba(0, 0, 0, 0.07);
    padding: 10px 0px;
    border-radius: 20px;
    margin-bottom: 10px;
    margin: 0 auto;
  }

  .box-content h4 {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  .box-content p {
    font-size: 0px;
  }

  .app-new-clients-listing .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    display: none !important;
  }

  //vishal
  // .india-map {
  //   height: 75vh;
  // }
  .multiple-states h2 {
    width: 100%;
  }

  .box-content {
    width: auto;
  }
}

@media (min-width: 320px) and (max-width: 900px) {
  .champaign-sev-box-content .champaign-sev-select-box p {
    min-height: 0px;
  }
  .welcome-back-content h2 {
    font-size: 20px;
    line-height: 30px;
  }
  .welcome-back .mobile-number {
    margin-top: 20px;
  }
  .send-otp {
    margin: 0;
  }
  .discover-more-content .swiper-horizontal {
    padding-bottom: 0px;
  }
  #EXPLORE_BG .nava-chandi-bg h4 {
    min-height: 0px;
  }
  #EXPLORE_BG .nava-chandi-bg p {
    min-height: 0px;
  }
}
@media (min-width: 320px) and (max-width: 375px) {
  // .MuiDialog-paperScrollPaper{
  //     padding: 10px;
  // }
  .send-otp-number input:not(:first-child) {
    margin-left: 10px;
  }
}
.swiper-button-prev {
  top: 98% !important;
  left: 48% !important;
}
.swiper-button-next {
  top: 98% !important;
  right: 47% !important;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .swiper-button-prev {
    top: 95% !important;
    left: 47% !important;
  }
  .swiper-button-next {
    top: 95% !important;
    right: 47% !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .swiper-button-prev {
    top: 97% !important;
    left: 46% !important;
  }
  .swiper-button-next {
    top: 97% !important;
    right: 47% !important;
  }
  .discover-more-content .swiper-horizontal {
    padding-bottom: 30px;
  }
}
@media (min-width: 320px) and (max-width: 500px) {
  .swiper-button-prev {
    top: 97% !important;
    left: 44% !important;
  }
  .swiper-button-next {
    top: 97% !important;
    right: 40% !important;
  }
  .discover-more-content .swiper-horizontal {
    padding-bottom: 10px;
  }
}
@media (min-width: 501px) and (max-width: 700px) {
  .swiper-button-prev {
    top: 97% !important;
    left: 44% !important;
  }
  .swiper-button-next {
    top: 97% !important;
    right: 44% !important;
  }
  .discover-more-content .swiper-horizontal {
    padding-bottom: 30px;
  }

  .about_us_recognise h2::before,
  .about_us_recognise h2::after {
    width: 140px;
  }

  .our-brands-content h2::before,
  .our-brands-content h2::after {
    width: 80%;
  }

  #ANANTA_BG .about-us-content:first-child::before {
    background-image: url("../images/devasmriti-digitally/nama.svg");
    width: 240px;
    height: 240px;
    top: 18px;
    right: -80px;
    z-index: -999;
  }
}
@media (min-width: 701px) and (max-width: 767px) {
  .swiper-button-prev {
    top: 97% !important;
    left: 45% !important;
  }
  .swiper-button-next {
    top: 97% !important;
    right: 47% !important;
  }
  .discover-more-content .swiper-horizontal {
    padding-bottom: 30px;
  }
}

@media (max-width: 425px) {
  ._0plu,
  .disabledButton {
    width: -webkit-fill-available;
  }
}

@media (min-width: 320px) and (max-width: 541px) {
  .add-to-cart-box-main-content .cart-image {
    width: 140px;
    min-width: 100px;
  }

  .add-to-cart-box-mini-content h4 {
    font-size: 14px !important;
    line-height: 24px;
    margin-bottom: 5px;
    width: 140px;
  }

  .add-to-cart-box-main-content p,
  .add-to-cart-box-mini-content h6 {
    font-size: 11px;
    margin: 6px 0;
  }

  // .add-to-cart-price{
  //   padding-top: 25px;
  // }

  .add-to-cart-box-mini-content {
    margin-left: 20px;
  }

  .check-out-panel .add-family-content h5,
  .custom-edit-profile h5 {
    font-size: 14px;
  }

  .all-seva-accordion .add-to-cart-box-mini-content h4 {
    font-size: 13px !important;
    line-height: 18px;
    margin-bottom: 4px;
  }

  .all-seva-accordion .add-to-cart-box-mini-content p {
    font-size: 11px;
    margin: 4px 0;
  }
}

@media (min-width: 320px) and (max-width: 540px) {
  .add-to-cart-box-main-content .cart-image {
    width: 100px;
    min-width: 80px;
  }
  .all-seva-accordion .cart-image {
    width: 100px;
    min-width: 80px;
    height: 95%;
  }

  .all-seva-accordion .cart-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .all-seva-accordion .add-to-cart-box-main-content {
    height: 130px;
  }
}

@media (min-width: 320px) and (max-width: 345px) {
  .app-new-add-to-cart .add-to-cart-box-mini-content {
    margin-left: 15px;
  }
  .send-otp-number input {
    width: 45px !important;
    height: 45px !important;
  }
}

@media (min-width: 320px) and (max-width: 361px) {
  .relation-content .relation h5 {
    font-size: 12px;
    margin: 0px 0px 3px 0px;
  }

  .profile-information-box .relation-content .relation p {
    font-size: 11px;
  }

  .personal-information.addr-flex-disable
    .personal-information-content
    h2::after {
    width: 27vw;
  }
}

// New CSS

.seva-box {
  border-radius: 16px;
  background-color: white;
  padding: 13px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid #e7e7e7;
  img {
    border-radius: 5px;
    width: 100%;
  }
  .sevaTitle {
    color: #e13f22;
    font-size: 0.9rem !important;
    font-weight: 500;
    // margin: 10px 0px;
    // height: 25px;
    // margin: 5px 0px;
  }
  .sevaPrice {
    color: #e13f22;
    font-size: 1rem;
    font-weight: 700;
    padding-bottom: 10px;
  }
  .sevaDesc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 37px;
    padding-bottom: 10px;
    cursor: pointer;
    div {
      font-size: 0.8rem;
      font-weight: 500;
    }
    img {
      width: 10px;
      height: 10px;
    }
  }
}
.addtoCart {
  display: inline-block;
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.btnWrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}
.footerSocialIcon {
  color: #ff5b00;
  font-size: 30px;
}

.progress-bar-container {
  width: 100%;
  // max-width: 600px;
  margin: 0 auto;
  // text-align: center;
}

.progress-bar {
  background-color: #eee;
  border-radius: 25px;
  overflow: hidden;
  height: 20px;
  width: 100%;
  margin: 10px 0;
  position: relative;
}

.progress-bar-fill {
  background: linear-gradient(to right, #ff5b00, #b3315b);
  height: 100%;
  transition: width 0.5s ease-in-out;
}

.progress-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  // color: #333;
  color: #ff5b00;
  font-weight: bold;
}
.customInput {
  height: 50px;
  border-radius: 30px;
  border: 1px solid #e7e7e7;
  padding: 8px 20px;
  z-index: 99999;
  font-size: 18px;
  font-weight: 700;
  width: 200px;
  &::placeholder {
    opacity: 0.4;
  }
}

/* Center the loader */
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -40%);
  margin-top: 100px;
}

/* Spin animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.donationFooter {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  border-radius: 20px 20px 0 0;
  padding: 20px;
  z-index: 9999;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1); /* Add shadow here */
}
.donationFooterBtn {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: white;
  background-color: rgb(255, 91, 0);
  border-radius: 10px;
}
.suggestedAmountWrapper {
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  position: relative;
}
.suggestedAmountWrapper1 {
  display: none;
}
.suggestedAmountCard {
  border: 1px solid #e7e7e7;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  padding: 10px;
  // width: auto;
  width: 100px;
  z-index: 99999;
  font-weight: 700;
  border-radius: 10px;
}
.suggestedAmountCard1 {
  border: 1px solid #e7e7e7;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  padding: 10px;
  // width: auto;
  width: 100px;
  z-index: 99999;
  font-weight: 700;
  border-radius: 10px 10px 0px 0px;
}
.selectedAmountCard1 {
  border: 1px solid #e7e7e7;
  padding: 10px;
  width: 100px;
  z-index: 99999;
  border-color: rgb(255, 91, 0);
  color: white;
  font-weight: 700;
  border-radius: 10px 10px 0px 0px;
  color: rgb(255, 91, 0);
  background-color: white;
}
.selectedAmountCard {
  border: 1px solid #e7e7e7;
  padding: 10px;
  width: 100px;
  z-index: 99999;
  border-color: rgb(255, 91, 0);
  color: white;
  font-weight: 700;
  border-radius: 10px;
  color: rgb(255, 91, 0);
  background-color: white;
}
.mostDonated {
  position: absolute;
  // margin-top: 40px;
  font-size: 12px;
  width: 100%;
  text-align: center;
  background-color: rgb(255, 91, 0);
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 0px 0px 10px 10px;
}
.customInputWrapper {
  display: flex;
  align-items: center;
}
.percentageDesktop {
  display: block;
}
.percentageMobile {
  display: none;
}
.starContainer {
  width: 65%;
}
.showDonate {
  display: block;
}
@media (max-width: 768px) {
  /* Adjust the max-width as needed */
  .footer .om_footer::after {
    display: none;
  }
  .suggestedAmountWrapper {
    display: none;
  }
  .customInputWrapper {
    display: flex;
    align-items: center;
    width: "100%";
  }
  .suggestedAmountWrapper1 {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-bottom: -20px;
  }
  .percentageDesktop {
    display: none;
  }
  .percentageMobile {
    display: block;
    font-size: 16px;
    // color: #333;
    color: #ff5b00;
    font-weight: bold;
    text-align: left;
  }
  .starContainer {
    width: 100%;
  }
  .showDonate {
    display: none;
  }
  .mostDonated {
    position: absolute;
    // margin-top: 40px;
    font-size: 10px;
    width: 100%;
    text-align: center;
    background-color: rgb(255, 91, 0);
    color: white;
    font-weight: bold;
    padding: 5px;
    border-radius: 0px 0px 10px 10px;
  }
  .selectedAmountCard {
    border: 1px solid #e7e7e7;
    padding: 10px;
    width: 80px;
    z-index: 99999;
    border-color: rgb(255, 91, 0);
    color: white;
    font-weight: 700;
    border-radius: 10px;
    color: rgb(255, 91, 0);
    background-color: white;
  }
  .selectedAmountCard1 {
    border: 1px solid #e7e7e7;
    padding: 10px;
    width: 80px;
    z-index: 99999;
    border-color: rgb(255, 91, 0);
    color: white;
    font-weight: 700;
    border-radius: 10px 10px 0px 0px;
    color: rgb(255, 91, 0);
    background-color: white;
  }

  .suggestedAmountCard {
    border: 1px solid #e7e7e7;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    padding: 10px;
    // width: auto;
    width: 70px;
    z-index: 99999;
    font-weight: 700;
    border-radius: 10px;
  }
  .suggestedAmountCard1 {
    border: 1px solid #e7e7e7;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    padding: 10px;
    // width: auto;
    width: 70px;
    z-index: 99999;
    font-weight: 700;
    border-radius: 10px 10px 0px 0px;
  }
  .tipContainer {
    padding: 20px 20px;
  }
  .tipWrapper {
    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    // align-items:center;
    padding-top: 20px;
  }
}

.yesBtn {
  border: 1px solid rgba(255, 91, 0, 0.4);
  padding: 5px 10px;
  margin-right: 10px;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
}
.yesBtnSelected {
  border: 1px solid rgba(255, 91, 0, 0.4);
  padding: 5px 10px;
  margin-right: 10px;
  background-color: #ff5b00;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.prasadAddress {
  border: 0.6px solid rgba(255, 91, 0, 0.4);
  border-radius: 10px;
  padding: 0px 20px;
  margin: 0px 20px;
  margin-bottom: 20px;
  background: white;
}
.prasadAddressMobile {
  border: 0.6px solid rgba(255, 91, 0, 0.4);
  border-radius: 10px;
  padding: 0px 20px;
  margin: 20px 0px;
  padding-bottom: 20px;
  background: white;
}

.tipContainer {
  padding: 20px 30px;
}

.tipWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}
