.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.progress-bar-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

.progress-bar {
  background-color: #eee;
  border-radius: 25px;
  overflow: hidden;
  height: 20px;
  width: 100%;
  margin: 10px 0;
  position: relative;
}

.progress-bar-fill {
  background: linear-gradient(to right, #b3315b, #b3315b);
  height: 100%;
  transition: width 0.5s ease-in-out;
}

.progress-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: #333;
}

.imageLoader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderLogo {
  width: 100px;
  height: 100px;
  animation: pulsate 1.5s infinite;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
